Polymer({
  is: 'account-verification-view',

  behaviors: [global.App.ElementBase],

  properties: {
    validToken: {
      type: Boolean,
      notify: true,
    },
    authToken: {
      type: String,
      notify: true,
    },
    user: {
      type: Object,
      notify: true,
    },
    company: {
      type: Object,
      notify: true,
    },
  },

  attached() {
    this.token = app.$.state.params.token;
    this.$.VerifyTokenAjax.generateRequest();
  },

  _successVerifyHandler(event, detail) {
    this.validToken = detail.response.token;
  },

  _successHandler(event, detail) {
    // @TODO to be fixed!
    this.$$('#authForm').reset();
    this.user = detail.response.user;
    this.company = detail.response.company;
    this.authToken = detail.response.token;
    app._loginSuccess(event, detail.response);
    debug('******', this.company, this.user, this.authToken);
  },

  _failureHandler(event, detail) {
    let message;
    const { response } = detail.request.xhr;
    if (response) {
      if (response.message && !response.detail) {
        message = response.message;
      } else if (response.message && response.detail) {
        message = response.detail;
      }
    } else {
      message = 'Echec de chargement!';
    }
    app.showToast(message);
  },

  _doVerify() {
    this.$$('#authForm').submit();
  },

  _goLogin() {
    app.$.state.goTo('login');
  },

  userLoad() {
    app.user = null;
  },

  companyLoad() {
    app.company = null;
  },

  tokenLoad() {
    app.token = null;
  },
});
