Polymer({
  is: 'client-overview',

  _entity: 'overview',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    id: {
      type: String,
      notify: true,
    },
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
  },

  observers: ['_loadFetchItems(id,dateRange.*)'],

  _dateFromLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      from: this.$.dateFrom.value,
    });
    this.set('dateRange', dateRange);
  },

  _dateUntilLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      until: this.$.dateUntil.value,
    });
    this.set('dateRange', dateRange);
  },

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });
  },

  _loadFetchItems(id, dateRange) {
    dateRange = Object.assign(this.dateRange, dateRange.base);
    this._fetchItems(`/admin/client/${id}/overview`, dateRange);
  },

  _computedTraveledDistanceByOrder(item) {
    if (!item.finishedCount || item.finishedCount === 0) {
      return '-';
    }
    const distance = item.traveledDistance / 1000;
    const rate = distance / item.finishedCount;
    return Number(rate.toFixed(2)).toLocaleString('fr') || 0;
  },

  _computedTraveledDistanceByOrderBooking(item) {
    if (!item.executedCount || item.executedCount === 0) {
      return '-';
    }
    const distance = item.traveledDistance / 1000;
    const rate = distance / item.executedCount;
    return Number(rate.toFixed(2)).toLocaleString('fr') || 0;
  },

  _convertToKm(item) {
    const distance = item.traveledDistance / 1000;
    return Number(distance.toFixed(2)).toLocaleString('fr') || 0;
  },

  _computedTotalbusinessWallet(orderbusinessWallet, orderBookingbusinessWallet) {
    const wallet = Number(orderbusinessWallet) + Number(orderBookingbusinessWallet);
    return this.$currency(wallet);
  },

  _computedHTbusinessWallet(orderbusinessWallet, orderBookingbusinessWallet) {
    let wallet = Number(orderbusinessWallet) + Number(orderBookingbusinessWallet);
    const feeValue = 0.1 * wallet; //  feeMode * wallet;
    wallet -= feeValue;
    return this.$currency(wallet);
  },
});
