const STATE_CLASS = {
  off: 1,
  idle: 2,
  busy: 3,
  order: 4,
};

Polymer({
  is: 'taxi-statistics',

  _entity: 'taxi',

  behaviors: [global.App.ListViewBase, global.App.MapHelpers],

  properties: {
    taxi: {
      type: String,
      notify: true,
    },
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
    data: {
      type: Object,
      notify: true,
      value: {},
    },
    stateFullDiagramOptions: {
      type: Object,
      value() {
        return {
          title: 'Etat / Connectivité / Position',
          hAxis: {
            title: 'Temps',
          },
          vAxis: {
            title: 'Etat / Connectivité',
            minValue: -4,
            maxValue: 4,
            ticks: [
              {
                v: 4,
                f: 'connecté',
              },
              {
                v: -4,
                f: 'déconnecté',
              },
              {
                v: 1,
                f: 'off',
              },
              {
                v: 2,
                f: 'libre',
              },
              {
                v: 3,
                f: 'occupé',
              },
              {
                v: 4,
                f: 'en course',
              },
              {
                v: -1,
                f: 'off*',
              },
              {
                v: -2,
                f: 'libre*',
              },
              {
                v: -3,
                f: 'occupé*',
              },
              {
                v: -4,
                f: 'en course*',
              },
            ],
          },
          backgroundColor: '#EEEEEE',
          colors: ['#999999', '#53A8FB', '#F1CA3A', '#E49307'],
          legend: {
            position: 'bottom',
          },
        };
      },
    },
    stateFullDiagramCols: {
      type: Array,
      value() {
        return [
          {
            label: 'Temps',
            type: 'datetime',
          },
          {
            label: 'Connectivité',
            type: 'number',
          },
          {
            label: 'Etat',
            type: 'number',
          },
          {
            label: 'Etat par position',
            type: 'number',
          },
        ];
      },
    },
    stateFullDiagramRows: {
      type: Array,
      value() {
        return [];
      },
    },
    connectivityDiagramOptions: {
      type: Object,
      value() {
        return {
          title: 'Connectivité',
          hAxis: {
            title: 'Temps',
          },
          vAxis: {
            title: 'Connectivité',
            minValue: 0,
            maxValue: 2,
            ticks: [
              {
                v: 1,
                f: 'déconnecté',
              },
              {
                v: 2,
                f: 'connecté',
              },
            ],
          },
          backgroundColor: '#EEEEEE',
          colors: ['#999999'],
          // legend: {
          //   position: 'bottom'
          // },
        };
      },
    },
    connectivityDiagramCols: {
      type: Array,
      value() {
        return [
          {
            label: 'Temps',
            type: 'datetime',
          },
          {
            label: 'Connectivité',
            type: 'number',
          },
        ];
      },
    },
    connectivityDiagramRows: {
      type: Array,
      value() {
        return [];
      },
    },
    stateDiagramOptions: {
      type: Object,
      value() {
        return {
          title: 'Etat',
          hAxis: {
            title: 'Temps',
          },
          vAxis: {
            title: 'Etat',
            minValue: 0,
            maxValue: 4,
            ticks: [
              {
                v: 1,
                f: 'off',
              },
              {
                v: 2,
                f: 'libre',
              },
              {
                v: 3,
                f: 'occupé',
              },
              {
                v: 4,
                f: 'en course',
              },
            ],
          },
          backgroundColor: '#EEEEEE',
          colors: ['#53A8FB'],
          // legend: {
          //   position: 'bottom'
          // },
        };
      },
    },
    stateDiagramCols: {
      type: Array,
      value() {
        return [
          {
            label: 'Temps',
            type: 'datetime',
          },
          {
            label: 'Etat',
            type: 'number',
          },
        ];
      },
    },
    stateDiagramRows: {
      type: Array,
      value() {
        return [];
      },
    },
    stateFromPositionDiagramOptions: {
      type: Object,
      value() {
        return {
          title: 'Etat par Position',
          hAxis: {
            title: 'Temps',
          },
          vAxis: {
            title: 'Etat',
            minValue: 0,
            maxValue: 4,
            ticks: [
              {
                v: 1,
                f: 'off',
              },
              {
                v: 2,
                f: 'libre',
              },
              {
                v: 3,
                f: 'occupé',
              },
              {
                v: 4,
                f: 'en course',
              },
            ],
          },
          backgroundColor: '#EEEEEE',
          colors: ['#F1CA3A'],
          // legend: {
          //   position: 'bottom'
          // },
        };
      },
    },
    stateFromPositionDiagramCols: {
      type: Array,
      value() {
        return [
          {
            label: 'Temps',
            type: 'datetime',
          },
          {
            label: 'Etat par position',
            type: 'number',
          },
        ];
      },
    },
    stateFromPositionDiagramRows: {
      type: Array,
      value: [],
    },
  },

  observers: ['_loadFetchItems(taxi,dateRange.*)'],

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });
  },

  _dateFromLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      from: this.$.dateFrom.value,
    });
    this.set('dateRange', dateRange);
  },

  _dateUntilLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      until: this.$.dateUntil.value,
    });
    this.set('dateRange', dateRange);
  },

  _loadFetchItems(taxi, dateRange) {
    dateRange = Object.assign(this.dateRange, dateRange.base);

    global.App.fetch(`/admin/taxi/${taxi}/history`, {
      query: dateRange || '',
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        this._loaded();

        this._historyHandler(response);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _historyHandler(response) {
    const data = {};

    const sort = this._sortBy('timestamp:date');

    data.from = new Date(response.from);
    data.until = new Date(response.until);

    data.connectivity = (response.connectivity || []).sort(sort);
    data.state = (response.state || []).sort(sort);
    data.position = (response.position || []).sort(sort);
    data.stateFromPosition = (response.stateFromPosition || []).sort(sort);

    this.set('data', data);

    this.set('items', [data]);

    this._updateStateDiagramData();

    // @TODO calculate total connectivity time, draw pie or bar chart

    // @TODO calculate time by state, draw pie or bar chart

    // @TODO calculate time by state from position, draw pie or bar chart
  },

  _updateStateDiagramData() {
    const sort = this._sortBy('0:date');

    let array;

    let stateFullDiagramRows = [];

    let connectivityDiagramRows = [];

    this.data.connectivity.forEach((item) => {
      const timestamp = new Date(item.timestamp);

      connectivityDiagramRows.push([
        timestamp,
        {
          v: item.connected ? 2 : 1,
          f: item.connected ? 'connecté' : 'déconnecté',
        },
      ]);

      stateFullDiagramRows.push([
        timestamp,
        {
          v: item.connected ? 4 : -4,
          f: item.connected ? 'connecté' : 'déconnecté',
        },
        null,
        null,
      ]);
    });

    connectivityDiagramRows = connectivityDiagramRows.sort(sort);

    connectivityDiagramRows.push([
      new Date(this.data.from.getTime()),
      {
        v: 1,
        f: 'déconnecté',
      },
    ]);

    connectivityDiagramRows.push([new Date(this.data.until.getTime()), null]);

    array = [];
    array.timestamps = [];

    connectivityDiagramRows.forEach((item, index) => {
      const timestamp = item[0].getTime();
      if (array.timestamps.indexOf(timestamp) === -1) {
        array.push(item);
        array.timestamps.push(timestamp);
      }
    });

    this.set('connectivityDiagramRows', array.sort(sort));

    let stateDiagramRows = [];

    this.data.state.forEach((item) => {
      if (!(item.state in STATE_CLASS)) {
        return;
      }

      const timestamp = new Date(item.timestamp);

      stateDiagramRows.push([
        timestamp,
        {
          v: STATE_CLASS[item.state],
          f: item.state,
        },
      ]);

      // stateFullDiagramRows.push([
      //   timestamp,
      //   null, {
      //     v: STATE_CLASS[item.state],
      //     f: item.state,
      //   },
      //   null
      // ]);
    });

    stateDiagramRows = stateDiagramRows.sort(sort);

    stateDiagramRows.push([
      new Date(this.data.from.getTime()),
      {
        v: 1,
        f: 'off',
      },
    ]);

    stateDiagramRows.push([new Date(this.data.until.getTime()), null]);

    array = [];
    array.timestamps = [];

    stateDiagramRows.forEach((item, index) => {
      const timestamp = item[0].getTime();
      if (array.timestamps.indexOf(timestamp) === -1) {
        array.push(item);
        array.timestamps.push(timestamp);
      }
    });

    this.set('stateDiagramRows', array.sort(sort));

    let stateFromPositionDiagramRows = [];

    this.data.stateFromPosition.forEach((item) => {
      if (!(item.state in STATE_CLASS)) {
        return;
      }

      const timestamp = new Date(item.timestamp);

      stateFromPositionDiagramRows.push([
        timestamp,
        {
          v: STATE_CLASS[item.state],
          f: item.state,
        },
      ]);

      stateFullDiagramRows.push([
        timestamp,
        null,
        null,
        {
          v: -STATE_CLASS[item.state],
          f: item.state,
        },
      ]);
    });

    stateFromPositionDiagramRows = stateFromPositionDiagramRows.sort(sort);

    stateFromPositionDiagramRows.push([
      new Date(this.data.from.getTime()),
      {
        v: 1,
        f: 'off',
      },
    ]);

    stateFromPositionDiagramRows.push([new Date(this.data.until.getTime()), null]);

    array = [];
    array.timestamps = [];

    stateFromPositionDiagramRows.forEach((item, index) => {
      const timestamp = item[0].getTime();
      if (array.timestamps.indexOf(timestamp) === -1) {
        array.push(item);
        array.timestamps.push(timestamp);
      }
    });

    this.set('stateFromPositionDiagramRows', array);

    stateFullDiagramRows = stateFullDiagramRows.sort(sort);

    stateFullDiagramRows.push([
      new Date(this.data.from.getTime()),
      {
        v: -4,
        f: 'déconnecté',
      },
      {
        v: STATE_CLASS.off,
        f: 'off',
      },
      {
        v: -STATE_CLASS.off,
        f: 'off',
      },
    ]);

    stateFullDiagramRows.push([new Date(this.data.until.getTime()), null, null, null]);

    array = [];
    array.timestamps = [];

    stateFullDiagramRows.forEach((item) => {
      const timestamp = item[0].getTime();
      const _index = array.timestamps.indexOf(timestamp);
      if (_index === -1) {
        array.push(item);
        array.timestamps.push(timestamp);
      } else {
        const _item = array[_index];
        array[_index] = [
          _item[0],
          _item[1] || item[1] || null,
          _item[2] || item[2] || null,
          _item[3] || item[3] || null,
        ];
      }
    });
    // this.set('stateFullDiagramRows', array.sort(sort));

    this.set('stateFullDiagramRows', stateFullDiagramRows);

    // this.set('stateFullDiagramRows', stateFullDiagramRows.sort(sort));
  },
});
