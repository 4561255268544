global.App.FormViewBehavior = global.App.utils.merge({}, global.App.ElementBase, {
  properties: {
    item: {
      type: Object,
      notify: true,
    },
    createOtherItem: {
      type: Boolean,
      notify: true,
      value: true,
    },
  },

  _submit() {
    this.$.form.do();
  },

  _successHandler(event, detail) {
    app.showToast(this._successMessage);
    this.fire('iron-signal', {
      // @DEPRECATE
      name: 'item-selected',
      data: detail[this._entity],
    });

    if (this.createOtherItem) {
      return;
    }

    const params = {};
    params[`${this._entity}Id`] = detail[this._entity].id;
    app.$.state.update(detail[this._entity].id, detail[this._entity]);
    app.$.state.goTo(this._entity, params);
  },

  // _failureHandler: function (event, detail) {
  //   // debug('FormView._failureHandler', arguments);
  //   app.showToast(detail);
  // },

  _closeSideView() {
    // this.issues = {};
    const form = document.querySelector('#form');
    form.issues = {};
    app.$.state.goTo(this._entity);
  },
});
