Polymer({
  is: 'booking-reminder',

  behaviors: [global.App.ElementBase],

  properties: {
    toastMessage: {
      type: String,
      notify: true,
      value: '',
    },
  },

  attached() {
    this.async(function () {
      global.App.socketio.on('admin:booking:reminder', (payload) => {
        const message = `Vous avez ${payload.result.length} réservation(s) non traitée(s) dans une heure!`;

        this._playSoundNotification('rappel réservation');

        this.set('toastMessage', message);
        this.$$('#bookingUnassignedToast').open();
      });
    }, 1000);
  },

  _close() {
    this.$$('#bookingUnassignedToast').close();
    // app.$.state.goTo('home');
    // global.App.EVENT.emit('booking:reminder:off');
  },
});
