Polymer({
  is: 'list-element',

  behaviors: [global.App.ListViewBase],

  properties: {
    items: {
      type: Array,
      value() {
        return [];
      },
    },
    loaded: {
      type: Boolean,
      value: false,
    },
  },

  attached() {
    global.App.EVENT.on('item:add', (response) => {
      if (!response) {
        return;
      }
      this.unshift('items', response);
    });

    global.App.EVENT.on('item:update', (response) => {
      if (!response) {
        return;
      }

      const index = this._getIndex(this.items, response);

      if (index > -1) {
        const item = { ...this.items[index], ...response };
        this.splice.apply(this, ['items', index, 1].concat(item));
      }
    });

    global.App.EVENT.on('item:delete', (response) => {
      if (!response) {
        return;
      }
      const index = this._getIndex(this.items, response);
      if (index > -1) {
        this.splice('items', index, 1);
      }
    });
  },
});
