Polymer({
  is: 'order-detail-view',

  _entity: 'order',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    selectedState: {
      type: String,
      value: 'order',
    },
  },

  attached() {
    this._fetchItems(`/admin/order/${this.item.id}`);

    this._emitDirections();

    if (!this.item.startPosition) {
      return;
    }

    global.App.EVENT.emit('map:markers:draw', {
      markerSet: [
        {
          position: this.item.startPosition,
          data: this.item.taxi,
        },
      ],
    });
  },

  _emitDirections() {
    global.App.EVENT.emit('map:route:draw', {
      requestAddress: this.item.requestAddress,
      destinationAddress: this.item.destinationAddress,
      route: this.item.route,
      startPosition: this.item.startPosition,
    });
  },
});
