Polymer({
  is: 'taxi-overview',

  _entity: 'overview',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    taxi: {
      type: String,
      notify: true,
    },
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
  },

  observers: ['_loadFetchItems(taxi,dateRange.*)'],

  _dateFromLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      from: this.$.dateFrom.value,
    });
    this.set('dateRange', dateRange);
  },

  _dateUntilLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      until: this.$.dateUntil.value,
    });
    this.set('dateRange', dateRange);
  },

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });
  },

  _loadFetchItems(taxi, dateRange) {
    dateRange = Object.assign(this.dateRange, dateRange.base);
    // this._fetchItems('/admin/taxi/' + taxi + '/overview', dateRange);

    if (this.loaded) {
      this.loaded = false;
    }

    global.App.fetch(`/admin/taxi/${taxi}/overview`, {
      query: dateRange || '',
    })
      .then((response) => {
        const orderSet = response.overview;

        this.set('item', orderSet);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _computedTotalCount(itemSet) {
    let count = 0;

    itemSet.forEach((item) => {
      count += item.count;
    });

    return count;
  },

  _computedTotalAmount(itemSet) {
    let amount = 0;

    itemSet.forEach((item) => {
      amount += item.amount;
    });

    return this.$currency(amount);
  },

  _computedTotalCommission(itemSet) {
    let commission = 0;

    itemSet.forEash((item) => {
      commission += item.commission;
    });

    return this.$currency(commission);
  },
});
