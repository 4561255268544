Polymer({
  is: 'client-edit-view',

  _entity: 'client',

  behaviors: [global.App.DetailViewBehavior, global.App.FormViewBehavior],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    createOtherItem: {
      type: Boolean,
      readOnly: true,
      value: false,
    },
  },

  _submit() {
    const body = this.$$('#form').serialize();

    global.App.fetch(`/admin/client/${this.item.id}/edit`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        global.App.EVENT.emit('item:update', response.client);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
