Polymer({
  is: 'map-component',

  behaviors: [global.App.ElementBase, global.App.MapHelpers],

  properties: {
    markerSet: {
      type: Array,
      notify: true,
    },

    requestAddress: {
      type: Object,
      notify: true,
    },

    destinationAddress: {
      type: Object,
      notify: true,
    },

    route: {
      type: Object,
      notify: true,
    },

    startPosition: {
      type: Object,
      notify: true,
    },
  },

  _mapReady() {},

  attached() {
    global.App.EVENT.on('map:route:draw', (payload) => {
      if (!payload) {
        return;
      }

      debug('map:route:draw', payload);

      this.set('requestAddress', payload.requestAddress);
      this.notifyPath('requestAddress');

      this.set('destinationAddress', payload.destinationAddress);
      this.notifyPath('destinationAddress');

      this.set('route', payload.route || null);
      this.notifyPath('route');

      this.set('startPosition', payload.startPosition || null);
      this.notifyPath('startPosition');
    });

    global.App.EVENT.on('map:markers:draw', (payload) => {
      if (!payload) {
        return;
      }

      debug('map:markers:draw', payload);

      const { markerSet } = payload;
      this.set('markerSet', markerSet);
      this.splice.apply(this, ['markerSet', 0, this.markerSet.length].concat(markerSet));
    });

    global.App.EVENT.on('map:markers:clear', (payload) => {
      if (!payload) {
        return;
      }
      debug('map:markers:clear:WIP');
    });

    global.App.EVENT.on('map:route:clear', (payload) => {
      if (!payload) {
        return;
      }
      debug('map:route:clear:WIP');
    });

    global.App.EVENT.on('map:clear', () => {
      if (!this.$.map) {
        return;
      }

      debug('map:clear');

      this.$.map.clear();
    });
  },
});
