Polymer({
  is: 'profile-settings-view',

  _entity: 'user',

  behaviors: [global.App.DetailViewBehavior],

  attached() {
    this._fetchItems('/user');
  },

  _goChangePassword() {
    this.selectedState = 'password-change';
  },

  _closeSideView() {
    this.selectedState = 'password-changed';
  },

  _goToState() {
    app.$.state.go('profile.password_change');
  },
});
