Polymer({
  is: 'client-list-view',

  _entity: 'client',

  behaviors: [global.App.ListViewBase],

  properties: {},

  attached() {
    this._loadPaginatedItems();

    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });

    global.App.EVENT.on('action:refresh', () => {
      this._loadPaginatedItems();
    });

    global.App.EVENT.on('item:search', (response) => {
      if (!response) {
        return;
      }
      this.set('query', response.item);
      this._loadPaginatedItems();
    });
  },

  _paginate(response) {
    if (response.page) {
      const { page } = response;
      this.set('page', page);
    }

    if (response.pageSize) {
      const { pageSize } = response;
      this.set('pageSize', pageSize);
    }

    this._loadPaginatedItems();
  },

  _computedFetchPaginatedParams() {
    const options = {
      paginate: {
        page: this.page,
        pageSize: this.pageSize,
      },
    };

    if (this.query) {
      options.q = this.query;
    } else {
      delete options.q;
    }
    return options;
  },

  _loadPaginatedItems() {
    this.set('loaded', false);

    global.App.fetch('/admin/client/list', {
      query: this._computedFetchPaginatedParams(),
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        this._loaded();

        if (!response.meta) {
          return;
        }

        const paginate = response.meta;
        this.set('page', paginate.page);
        this.set('count', paginate.count);
        this.set('pageSize', paginate.pageSize);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
