Polymer({
  is: 'admin-detail-view',

  _entity: 'admin',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    selectedState: {
      type: String,
      value: 'profile',
    },
  },

  attached() {
    this._fetchItems(`/admin/admin/${this.item.id}`);
  },
});
