
require('./sms-form.html');

const RegisterHtmlTemplate = require('polymer-webpack-loader/register-html-template');

RegisterHtmlTemplate.register("<dom-module id=sms-item> <template> <style include=shared-styles></style> <style>:host{display:block;background-color:rgba(206,215,219,.6);border-radius:4px;font-family:monospace}:host:hover{@apply (--shadow-elevation-2dp);}.-btn{position:absolute;top:0;right:0}.-btn iron-icon{padding:16px 0 16px 16px}.-text-box{line-height:1.38}</style> <paper-material class=\"-padding-1x layout vertical\" elevation=0> <div class=\"-btn layout horizontal\"> <div style=\"padding:16px 0 16px 16px\"> <span class=\"-text-uppercase -text-small\"> {{$time(item.timestamp)}} | {{$date(item.timestamp, 'DD MMM')}} </span> </div> <iron-icon icon=[[_computedIcon(smsStateSet,item.state)]] class$=[[_computedClass(smsStateSet,item.state)]]></iron-icon> <paper-menu-button horizontal-offset=-120 disabled$=\"{{_in(item.state, 'sent')}}\"> <paper-icon-button icon=more-vert class=dropdown-trigger alt=menu></paper-icon-button> <paper-menu class=dropdown-content> <paper-icon-item on-tap=_resendSMS> <iron-icon icon=send item-icon=\"\"></iron-icon> Réenvoyer </paper-icon-item> <paper-icon-item on-tap=_ignoreSMS> <iron-icon icon=archive item-icon=\"\"></iron-icon> Archiver </paper-icon-item> </paper-menu> </paper-menu-button> </div> <span class=\"-text-secondary -padding-1x layout vertical\" style=line-height:1.38> <span>{{item.recipient.name}} </span> <span class=-text-small> {{item.to}}</span> </span> <span class=\"-text-small -padding-1x -text-box\"> {{item.message}} </span> </paper-material> </template> </dom-module>");

RegisterHtmlTemplate.register("<dom-module id=sms-manual-list-element> <template> <style include=shared-styles></style> <style>:host{display:block}</style> <list-element items={{items}} loaded={{loaded}}> <empty-card class=empty-state message=\"Aucun SMS est envoyé!\" icon=notification:sms></empty-card> <div class=\"item-list -margin-1x\"> <template is=dom-repeat items={{items}}> <sms-item item={{item}} on-ignore=_ignoreSMS on-resend=_resendSMS class=-margin-1x-bottom></sms-item> </template> </div> </list-element> <iron-signals on-iron-signal-search-action=_search></iron-signals> <iron-signals on-iron-signal-refresh-action=_load></iron-signals> </template> </dom-module>");

RegisterHtmlTemplate.register("<dom-module id=sms-order-list-element> <template> <style include=shared-styles></style> <style>:host{display:block}</style> <list-element items={{items}} loaded={{loaded}}> <empty-card class=empty-state message=\"Aucun SMS est envoyé!\" icon=notification:sms></empty-card> <div class=\"item-list -margin-1x\"> <template is=dom-repeat items={{items}}> <sms-item item={{item}} on-ignore=_ignoreSMS on-resend=_resendSMS class=-margin-1x-bottom></sms-item> </template> </div> </list-element> <iron-signals on-iron-signal-search-action=_search></iron-signals> <iron-signals on-iron-signal-refresh-action=_load></iron-signals> </template> </dom-module>");

RegisterHtmlTemplate.register("<dom-module id=sms-booking-list-element> <template> <style include=shared-styles></style> <style>:host{display:block}</style> <list-element items={{items}} loaded={{loaded}}> <empty-card class=empty-state message=\"Aucun SMS est envoyé!\" icon=notification:sms></empty-card> <div class=\"item-list -margin-1x\"> <template is=dom-repeat items={{items}}> <sms-item item={{item}} on-ignore=_ignoreSMS on-resend=_resendSMS class=-margin-1x-bottom></sms-item> </template> </div> </list-element> <iron-signals on-iron-signal-search-action=_search></iron-signals> <iron-signals on-iron-signal-refresh-action=_load></iron-signals> </template> </dom-module>");

RegisterHtmlTemplate.register("<dom-module id=map-sms-view> <template> <style include=shared-styles></style> <style>:host{display:block;@apply (--layout-fit);@apply (--layout);@apply (--layout-vertical);}</style> <div> <sms-form id=form class=\"-padding-1x flex relative\" sms={{sms}} on-success=_successHandler on-failure=_failureHandler></sms-form> <div class=\"horizontal layout -margin-1x-right -margin-1x-left\"> <paper-button id=sendSMSButton autofocus=\"\" raised=\"\" class=\"-primary-action flex\" on-tap=_sendSMS>Envoyer SMS</paper-button> </div> </div> <paper-tabs noink=\"\" attr-for-selected=value selected={{selectedState}}> <paper-tab value=manual-sms> <span class=-text-capitalize>Manuel</span> <span class=\"-notif-bubble -grey\">{{manualSMSCount}}</span> </paper-tab> <paper-tab value=auto-sms> <span class=-text-capitalize>Auto</span> <span class=\"-notif-bubble -grey\">{{autoSMSCount}}</span> </paper-tab> <paper-tab value=booking-sms> <span class=-text-capitalize>Réservation</span> <span class=\"-notif-bubble -grey\">{{bookingSMSCount}}</span> </paper-tab> </paper-tabs> <iron-pages attr-for-selected=data-state selected=[[selectedState]] class=\"flex relative\"> <div data-state=manual-sms> <template is=dom-if if=\"{{_is(selectedState, 'manual-sms')}}\"> <sms-manual-list-element sms-count={{manualSMSCount}} restamp=\"\"> </sms-manual-list-element> </template> </div> <div data-state=auto-sms> <template is=dom-if if=\"{{_is(selectedState, 'auto-sms')}}\"> <sms-order-list-element sms-count={{autoSMSCount}} restamp=\"\"> </sms-order-list-element> </template> </div> <div data-state=booking-sms> <template is=dom-if if=\"{{_is(selectedState, 'booking-sms')}}\"> <sms-booking-list-element sms-count={{bookingSMSCount}} restamp=\"\"></sms-booking-list-element> </template> </div> </iron-pages> </template> </dom-module>");

require('./map-sms-view.js');
