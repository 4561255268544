const FILTER_ORDER_FOLLOWUP_SET = {
  state: [
    {
      value: global.App.CONST.ORDER_STATE.PENDING,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[global.App.CONST.ORDER_STATE.PENDING],
      checked: true,
    },
    {
      value: global.App.CONST.ORDER_STATE.STARTED,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[global.App.CONST.ORDER_STATE.STARTED],
      checked: true,
    },
    {
      value: global.App.CONST.ORDER_STATE.ONHOLD,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[global.App.CONST.ORDER_STATE.ONHOLD],
      checked: true,
    },
    {
      value: global.App.CONST.ORDER_STATE.ONGOING,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[global.App.CONST.ORDER_STATE.ONGOING],
      checked: true,
    },
  ],
};

const FILTER_ORDER_FOLLOWUP_INITIAL = {
  state: FILTER_ORDER_FOLLOWUP_SET.state.filter((item) => item.checked).map((item) => item.value),
  type: global.App.CONST.ORDER_TYPE.IMMEDIATE,
};

const ORDER_FOLLOWUP_IGNORED_STATE = [
  global.App.CONST.ORDER_STATE.FINISHED,
  global.App.CONST.ORDER_STATE.DONE,
  global.App.CONST.ORDER_STATE.CANCELLED,
  global.App.CONST.ORDER_STATE.TIMEDOUT,
];

Polymer(
  global.App.utils.merge({}, global.App.ListViewBase, {
    is: 'map-order-followup-view',

    _entity: 'home',

    behaviors: [global.App.ListViewBase],

    properties: {
      filterBySet: {
        type: Array,
        value: FILTER_ORDER_FOLLOWUP_SET,
      },
      filterByValue: {
        type: Object,
        notify: true,
        value: FILTER_ORDER_FOLLOWUP_INITIAL,
      },
      selectedItem: {
        type: Object,
        notify: true,
      },
    },

    observers: ['_fetchByState(filterByValue.state.*)'],

    _fetchByState(filterBy) {
      this._fetchItems({
        filterBy: this.filterByValue,
      });
    },

    _fetchItems(fetchOptions) {
      const end = new Date();
      const start = new Date(end.getTime() - global.App.CONST.DISTANCE);

      const options = {
        entity: 'order',
        query: {
          filterBy: {
            initiatedAt: {
              '>=': start,
              '<': end,
            },
          },
        },
      };

      if (fetchOptions.q) {
        options.query.q = fetchOptions.q;
      }

      if (fetchOptions.filterBy) {
        options.query.filterBy = Object.assign(options.query.filterBy, fetchOptions.filterBy);
      }

      this._getItems('/admin/order', options);
    },

    ready() {
      global.App.EVENT.on('item:search', (response) => {
        if (!response) {
          return;
        }
        this._search('/admin/order', response.item);
      });

      global.App.EVENT.on('action:refresh', () => {
        this._fetchByState();
      });

      global.App.socketio.on('admin:order:state', (payload) => {
        const item = payload.order;

        if (item.type !== global.App.CONST.ORDER_TYPE.IMMEDIATE) {
          return;
        }

        const index = item.state === global.App.CONST.ORDER_STATE.TIMEDOUT
          ? this._getTimedoutIndex(this.items, item)
          : this._getIndex(this.items, item);

        if (index > -1) {
          if (ORDER_FOLLOWUP_IGNORED_STATE.indexOf(item.state) !== -1) {
            if (item.state === global.App.CONST.ORDER_STATE.CANCELLED) {
              this._playSoundNotification(global.App.LANG.CURRENT.LABEL.RIDE_NOTIFICATION[item.type][item.state]);
            }

            this.splice('items', index, 1);
            return;
          }
          this.splice('items', index, 1, item);
        } else {
          if (ORDER_FOLLOWUP_IGNORED_STATE.indexOf(item.state) !== -1) {
            return;
          }

          // PLAY SOUND_NOTIFICATION
          this._playSoundNotification(global.App.LANG.CURRENT.LABEL.RIDE_NOTIFICATION[item.type][item.state]);

          this.unshift('items', item);
        }
      });
    },
  }),
);
