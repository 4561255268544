Polymer({
  is: 'statistics-overview',

  behaviors: [global.App.ElementBase],

  properties: {
    data: {
      type: Object,
      notify: true,
      value() {
        return {
          operatorTotal: '?',
          operatorOnline: '?',

          driverTotal: '?',
          driverOnline: '?',

          clientTotal: '?',
          clientOnline: '?',

          orderImmediateTotal: '?',
          orderImmediateDone: '?',

          orderBookingTotal: '?',
          orderBookingDone: '?',
        };
      },
    },
  },

  attached() {
    this.async(() => {
      global.App.socketio.on('admin:alert', (payload) => {
        if (!payload) {
          return;
        }

        this._playSoundNotification(payload.message);

        if (payload.url) {
          app.showToast(payload.message, {
            actionLabel: 'Agir',
            action() {
              window.open(payload.url, '_blank');
            },
          });
        } else {
          app.showToast(payload.message);
        }
      });

      global.App.socketio.on('admin:statistics:overview', (payload) => {
        const data = { ...this.data, ...payload };
        this.set('data', data);
      });

      global.App.socketio.on('admin:online:admin', (value) => {
        this.set('data.operatorOnline', value);
      });

      global.App.socketio.on('admin:online:driver', (value) => {
        this.set('data.driverOnline', value);
      });

      global.App.socketio.on('admin:online:client', (value) => {
        this.set('data.clientOnline', value);
      });
    }, 1000);
  },
});
