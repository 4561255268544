Polymer({
  is: 'taxi-detail',

  _entity: 'taxi',

  behaviors: [global.App.ListViewBase, global.App.MapHelpers],

  properties: {
    TaxiSubscriptionTypeClasses: {
      type: Object,
      value: global.App.SHARED.TAXI_SUBSCRIPTION_TYPE_CLASS_SET,
    },
  },

  attached() {
    global.App.EVENT.on('date:change', (response) => {
      debug('date:change:taxi-detail', response);
    });
  },
});
