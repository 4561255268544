global.App.TemplateExtraHelpers = {
  _fireSignal(value, event) {
    this.fire('iron-signal', {
      // @DEPRECATE
      name: event,
      data: {
        item: value,
      },
    });
  },

  callClient(event, detail) {
    const { item } = event.model;
    window.open(`tel:${item.client.user.phone}`, '_top');
  },

  callDriver(event, detail) {
    const { item } = event.model;
    window.open(`tel:${item.driver.user.phone}`, '_top');
  },

  callUser(event, detail) {
    const { item } = event.model;
    window.open(`tel:${item.user.phone}`, '_top');
  },

  _callCurrentDriver(event, detail) {
    const { item } = event.model;
    window.open(`tel:${item.driver.user.phone}`, '_top');
  },

  /**
   * play text sound
   *
   * @method _playSoundNotification
   *
   * @attribute options
   * @type Object
   * @default null
   * options={
   *   text:"text to be played",
   *   lang: 'fr-FR' //default-value
   *  }
   */

  _playSoundNotification(text, options) {
    if (window.SpeechSynthesisUtterance === undefined) {
      // Not supported
      return;
    }

    if (window.speechSynthesis === undefined) {
      // Not supported
      return;
    }

    if (!text) {
      return;
    }

    const utterance = new SpeechSynthesisUtterance();

    utterance.voice = speechSynthesis.getVoices().filter(
      (voice) => voice.lang === 'fr-FR', // options.lang
    )[0];

    // utterance.voiceURI = utterance.voice.voiceURI || 'native';

    utterance.voiceURI = 'native';
    utterance.lang = options && options.lang ? options.lang : 'fr-FR';

    utterance.volume = 1; // 0 to 1
    utterance.rate = 1; // 0.1 to 10
    utterance.pitch = 1; // 0 to 2
    utterance.text = text;

    // utterance.onerror = function (event) {
    //   debug("play-utterance...error", event);
    // };

    // utterance.onend = function (event) {
    //   debug("play-utterance...end", event);
    // };

    // utterance.onstart = function (event) {
    //   debug("play-utterance...start", event);
    // };

    if (speechSynthesis.speaking) {
      speechSynthesis.cancel();
    }

    if (speechSynthesis.pending) {
      speechSynthesis.resume();
    }

    speechSynthesis.speak(utterance);
  },
};
