Polymer({
  is: 'order-state-utils',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    orderCancelReasonSet: {
      type: Array,
      readOnly: true,
      value() {
        return global.App.SHARED.ORDER_CANCEL_REASON_SET;
      },
    },
    selectedCancelReason: {
      type: Object,
      value() {
        return global.App.SHARED.ORDER_CANCEL_REASON_SET[0];
      },
    },
    cancelNote: {
      type: 'string',
      notify: true,
    },
    driver: {
      type: Object,
      notify: true,
    },
  },

  _copyId() {
    if (!document.queryCommandSupported('copy')) {
      app.showToast("'copy' n'est pas supporté par votre browser");
      return;
    }

    const textArea = document.createElement('textarea');
    textArea.value = this.item.id;
    document.body.appendChild(textArea);
    textArea.select();

    try {
      const successful = document.execCommand('copy');
      if (successful) {
        app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
      } else {
        app.showToast(global.App.LANG.CURRENT.LABEL.FAILURE);
      }
    } catch (err) {
      app.showToast(global.App.LANG.CURRENT.LABEL.FAILURE);
    }

    document.body.removeChild(textArea);
  },

  _goAddNote() {
    this.set('selectedItem', this.item);
    this.set('noteInternal', this.item.noteInternal);
    this.$$('#addNoteDialog').open();
  },

  _doAddNote() {
    const body = {
      noteInternal: this.noteInternal,
    };

    global.App.fetch(`/admin/order/${this.item.id}/add-internal-note`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        global.App.EVENT.emit('item:update', response.order);
        app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
      })
      .catch((error) => {
        app.showToast(error.message);
      });
  },

  _goFinishOrder() {
    this.set('selectedItem', this.item);
    this.$$('#finishOrderDialog').open();
  },

  _doFinishOrder() {
    const payload = {
      orderId: this.selectedItem.id,
      noteInternal: this.noteInternal,
      costFromMeterEstimate: this.costFromMeterEstimate,
    };

    this._send(payload, 'finalize');
  },

  _goCancelOrder() {
    this.set('selectedItem', this.item);
    this.$$('#cancelOrderDialog').open();
  },

  _doCancelOrder() {
    this._send(
      {
        orderId: this.selectedItem.id,
        cancelReason: this.selectedCancelReason.value,
        cancelNote: this.cancelNote,
      },
      'cancel',
    );
  },

  _goAddTag() {
    this.$$('#addTagDialog').open();
  },

  _addTag() {
    this.$$('#form').do();
  },

  _reinitiateOrder() {
    this._send(
      {
        orderId: this.item.id,
      },
      'reinitiate',
    );
  },

  _relaunchOrder() {
    app.$.state.goTo('home.order');
    setTimeout(() => {
      global.App.EVENT.emit('order:relaunch', {
        item: this.item,
      });
    }, 500);
  },

  _goSetTaxi() {
    this.$$('#setTaxiDialog').open();
  },

  _goRetractTaxi() {
    this.$$('#retractTaxiDialog').open();
  },

  _doAttributeTaxi() {
    global.App.socketio.emit(
      '/admin/booking/:orderId/assign',
      {
        orderId: this.item.id,
        taxiId: this.driver.taxi.id,
      },
      (error, response) => {
        if (error) {
          app.showToast(error.message);
          return;
        }
        global.App.EVENT.emit('item:update', response.booking);
        this.$$('#setTaxiDialog').close();
        app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
      },
    );
  },

  _doRetractTaxi() {
    global.App.socketio.emit(
      '/admin/booking/:orderId/retract',
      {
        orderId: this.item.id,
      },
      (error, response) => {
        if (error) {
          app.showToast(error.message);
          return;
        }
        global.App.EVENT.emit('item:update', response.booking);
        this.$$('#retractTaxiDialog').close();
        app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
      },
    );
  },

  _fetchDriverOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/driver',
      item: 'driver',
      key: 'name',
    });
  },

  _send(item, action) {
    global.App.socketio.emit(`/admin/order/:orderId/${action}`, item, (error, result) => {
      if (error) {
        app.showToast(error.message);
        return;
      }
      app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
      // global.App.EVENT.emit('item:update', result[this._entity]);
    });
  },
});
