const DEVICE_CONNECTED_SET = {
  true: {
    label: 'appareil connecté',
    icon: 'communication:phonelink-ring',
    class: '-text-positive',
  },
  false: {
    label: 'appareil déconnecté',
    icon: 'communication:phonelink-erase',
    class: '-text-negative',
  },
};

Polymer(
  global.App.utils.merge({}, global.App.ListViewBase, {
    is: 'map-view',

    _entity: 'home',

    behaviors: [global.App.ElementBase, global.App.MapHelpers, global.App.RequestUtils],

    properties: {
      selectedState: {
        type: String,
        notify: true,
        value() {
          return app.state.sub ? app.state.sub.name : 'home';
        },
      },
      selectedSubState: {
        type: String,
        notify: true,
        value() {
          // return 'immediate';
          return 'booking';
        },
      },
      mapBounds: {
        type: Object,
        notify: true,
      },
      smsAgentConnected: {
        type: Object,
        notify: true,
        value: false,
      },
      search: {
        type: String,
        notify: true,
      },
      searchResults: {
        type: Array,
        notify: true,
      },
      searchPlaceholder: {
        type: String,
        notify: true,
        value: 'Rechercher une adresse',
      },
      smsAgentConnectedSet: {
        type: Object,
        value: DEVICE_CONNECTED_SET,
      },
      selectedTaxiId: {
        type: Number,
        notify: true,
        value: -1,
      },
      taxiSet: {
        // to display taxies in the map bounds as marker + info window
        type: Array,
        notify: true,
        value: [],
      },
      selectedTaxi: {
        // to display currently selected taxi in list
        type: Object,
        notify: true,
        value: null,
      },
      selectedOrder: {
        // to display currently selected order in list
        type: Object,
        notify: true,
        value: null,
      },
      requestPosition: {
        type: Object,
        notify: true,
      },
      destinationPosition: {
        type: Object,
        notify: true,
      },
      route: {
        type: Object,
        notify: true,
      },
      startPosition: {
        type: Object,
        notify: true,
      },
    },

    observers: [
      '_goToSelectedState(selectedState)',
      '_computedSearchPlaceholder(searchResults)',
      '_inputAddressChanged(search)',
    ],

    _inputAddressChanged(search) {
      if (this.destinationPosition || !this.search) {
        return;
      }

      const query = {
        // radius: 10000
      };

      if (Array.isArray(search)) {
        query.latlng = this._position(search);
      } else {
        query.address = search;
      }

      function _inputAddressChanged() {
        global.App.socketio.emit('/shared/maps/address', query, (error, result) => {
          if (error) {
            app.showToast(error.message);
            return;
          }

          this.set('searchResults', result);
        });
      }

      this.debounce('_debounceChangedSearchValue', _inputAddressChanged, 800);
    },

    _computedOpened(searchResults) {
      if (!this.$$('#searchInputMap')) {
        return false;
      }

      if (!searchResults.length) {
        return false;
      }

      return !!this.$$('#searchInputMap') && this.$$('#searchInputMap').value !== '' && !!searchResults;
    },

    _computedSearchPlaceholder(searchResults) {
      if (searchResults && !searchResults.length) {
        return;
      }

      let placeholder;

      if (searchResults && searchResults.length) {
        placeholder = searchResults[0].zone;
      }

      this.set('searchPlaceholder', placeholder);
    },

    _mapBoundsLoad() {
      this.mapBounds = this.mapBounds || null;
    },

    _smsAgentConnectedLoad() {
      this.smsAgentConnected = this.smsAgentConnected || null;
    },

    _goToSelectedLocation(event, detail) {
      const { item } = event.model;
      this._computedSearchPlaceholder([item]);
      this._setCenter(item.position[0], item.position[1]);

      if (this.search) {
        this.set('search', '');
      }
      this.$.searchSuggestions.close();
    },

    ready() {
      global.App.EVENT.on('map:order:itinerary', (payload) => {
        if (!payload) {
          return;
        }

        this.push('items', payload.taxi);
        this.set('requestPosition', payload.requestPosition);
        this.set('destinationPosition', payload.destinationPosition);
        this.set('route', payload.route);
        this.set('startPosition', payload.startPosition);

        if (!payload.taxi) {
          return;
        }

        this.splice.apply(this, ['items', 0, this.items.length].concat(payload.taxi));
      });

      global.App.EVENT.on('map:taxi:track', (payload) => {
        if (!payload || (payload && !payload.item)) {
          return;
        }

        const { item } = payload;
        const markerIndex = this._getIndex(this.taxiSet, item);

        if (markerIndex > -1) {
          this.set('selectedTaxiId', this.taxiSet[markerIndex].id);
        }
      });

      global.App.EVENT.on('map:taxi:refresh', (payload) => {
        if (!payload) {
          return;
        }
        this.set('taxiSet', []);
        this.set('taxiSet', payload);
      });

      global.App.EVENT.on('map:set', (payload) => {
        if (!payload || !payload.field || !payload.value) {
          return;
        }

        this.set(payload.field, payload.value);
      });

      global.App.EVENT.on('map:position:set', (payload) => {
        if (!payload || !payload.position) {
          return;
        }

        this._setCenter(payload.position[0], payload.position[1]);
      });

      global.App.EVENT.on('map:route:set', (payload) => {
        if (!payload || !payload.value) {
          return;
        }

        this.set('route', payload.value);
      });

      global.App.EVENT.on('map:order:reset', (payload) => {
        this._clearMap();
      });

      global.App.EVENT.on('map:position:fix', (payload, ack) => {
        payload = payload || {};

        const position = [this.$.map.map.getCenter().lat, this.$.map.map.getCenter().lng];

        ack({
          position,
        });

        if (payload.showNearbyTaxi) {
          global.App.socketio.emit(
            '/admin/taxi/nearby',
            {
              position,
              extra: true,
            },
            (error, result) => {
              if (error) {
                app.showToast(error.message);
                return;
              }
              this.set('taxiSet', result.taxi);
            },
          );
        }
      });

      global.App.socketio.on('admin:sms:state', (payload) => {
        this.set('smsAgentConnected', payload.state === 'connected');
      });

      global.App.socketio.on('admin:taxi:state', (taxi) => {
        if (app.state.name !== 'home') {
          return;
        }

        const index = this._getIndex(this.taxiSet, taxi);

        const position = L.latLng(taxi.position[0], taxi.position[1]);

        const inBounds = this.$.map.map.getBounds().contains(position);

        if (index > -1) {
          if (inBounds) {
            // this.splice.apply(this, ['taxiSet', index, 1].concat(taxi));
            this.taxiSet[index] = taxi;
            this.notifyPath('taxiSet', this.taxiSet.slice());
          } else {
            this.splice('taxiSet', index, 1);
          }
        } else if (inBounds) {
          this.push('taxiSet', taxi);
        }
      });

      // global.App.EVENT.on('booking:reminder:on', (payload) => {
      //   this.set('notification', true);
      // });

      // global.App.EVENT.on('booking:reminder:off', (payload) => {
      //   this.set('notification', false);
      // });
    },

    _goToSelectedState(selectedState) {
      this._clearMap();
      this.$.tabs.select(this.selectedState);

      app.$.state.goTo(selectedState);

      this._findTaxiThrottled();
    },

    _setCenter(lat, lng) {
      const center = [lat, lng];
      this.set('center', center);
      this.set('search', this._position(center));
    },

    _clearMap() {
      if (this.requestPosition) {
        this.set('requestPosition', null);
      }

      if (this.destinationPosition) {
        this.set('destinationPosition', null);
      }

      if (this.startPosition) {
        this.set('startPosition', null);
      }

      if (this.route) {
        this.set('route', null);
      }
    },

    _mapReady() {
      this._findTaxiDelayed();
    },

    _findTaxiDelayed() {
      this.async(this._findTaxiThrottled, 2000);
    },

    _findTaxiThrottled() {
      this._findTaxiThrottledBound = this._findTaxiThrottledBound || global.App.throttle(this._findTaxi.bind(this), 5000);
      this._findTaxiThrottledBound();
    },

    _findTaxi() {
      // debug('_findTaxi');
      if (!this.$.map.map) {
        return;
      }

      this._setCenter(this.$.map.map.getCenter().lat, this.$.map.map.getCenter().lng);
      this.set('zoom', this.$.map.map.zoom);

      const bound = this.$.map.map.getBounds();

      // debug('_findTaxi', this.bound);

      if (!bound) {
        return;
      }

      const northEast = [bound.getNorthEast().lat, bound.getNorthEast().lng];

      const southWest = [bound.getSouthWest().lat, bound.getSouthWest().lng];

      this.mapBounds = {
        northEast,
        southWest,
      };

      // debug('_findTaxi', this.mapBounds);

      this.fire('iron-signal', {
        // @DEPRECATE
        name: 'map-bounds-changed',
        data: {
          item: {
            bounds: this.mapBounds,
          },
        },
      });
    },

    _trackOnList(event, detail) {
      this.fire('iron-signal', {
        // @DEPRECATE
        name: 'taxi-selected',
        data: {
          item: event.model.item,
        },
      });
    },

    _setOrder(event, detail) {
      const order = detail.item;
      this.set('requestPosition', order.requestPosition);
      this.set('destinationPosition', order.destinationPosition);
      this.set('route', order.route);
      this.set('startPosition', order.startPosition);
    },

    _isSelectedTaxi(selectedTaxiId, item) {
      return selectedTaxiId === item.id ? 1 : 0.6;
    },
  }),
);
