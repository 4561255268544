/*
 * add query param to change envirenment (`CONFIG`)
 * e.g.
 *   ?env=development
 *   ?env=production
 */

const CONFIG_PRESET = {};

CONFIG_PRESET.PRODUCTION = {
  name: 'production',
  // ENDPOINT: window.location.origin + '/api', // proxy production
  ENDPOINT: 'https://api.etaxi.tn', // target production
  CLIENT_APP_ENDPOINT: 'https://admin.etaxi.tn',
  MEDIA_ENDPOINT: 'https://media.etaxi.tn',
  SENTRY_DSN: 'https://0892997055c741d7af963c5fdf03927a@sentry.etaxi.tn/6',
};

CONFIG_PRESET.DEVELOPMENT = {
  name: 'development',
  ENDPOINT: window.location.port
    ? `${window.location.protocol}//${window.location.hostname}:5000`
    : 'http://192.168.1.111:5000',
  CLIENT_APP_ENDPOINT: window.location.port
    ? `${window.location.protocol}//${window.location.hostname}:5510`
    : 'http://192.168.1.111:5510',
  MEDIA_ENDPOINT: window.location.port
    ? `${window.location.protocol}//${window.location.hostname}:5020`
    : 'http://192.168.1.111:5020',
  SENTRY_DSN: localStorage.SENTRY_DSN || 'https://3870ba731afd46bfa1e352a2b32ee9ca@sentry.etaxi.tn/3',
};

CONFIG_PRESET.STAGING = {
  name: 'staging',
  ENDPOINT: CONFIG_PRESET.PRODUCTION.ENDPOINT,
  CLIENT_APP_ENDPOINT: CONFIG_PRESET.PRODUCTION.CLIENT_APP_ENDPOINT,
  MEDIA_ENDPOINT: CONFIG_PRESET.PRODUCTION.MEDIA_ENDPOINT,
  SENTRY_DSN: CONFIG_PRESET.DEVELOPMENT.SENTRY_DSN,
};

window.location.queryParams = {};
window.location.search
  .substr(1)
  .split('&')
  .forEach((value) => {
    value = value.split('=');
    window.location.queryParams[value[0]] = value[1];
  });

let env = window.location.queryParams.env || localStorage.env;
// eslint-disable-next-line no-nested-ternary
env = env ? String(env).toUpperCase() : window.location.port ? 'DEVELOPMENT' : 'PRODUCTION';

if (env !== localStorage.env) {
  localStorage.env = env;
}

const CONFIG = CONFIG_PRESET[env] || CONFIG_PRESET.DEVELOPMENT;

if (env === 'DEVELOPMENT') {
  if (!localStorage.debug) {
    localStorage.debug = 'etaxi*';
  }
  if (!localStorage['etaxi.authCredentials']) {
    localStorage['etaxi.authCredentials'] = JSON.stringify({
      username: 'admin@etaxi.tn',
      password: 'adminpass',
    });
  }
}

const { APP_VERSION } = require('./release');

CONFIG.SCOPE = 'admin';
CONFIG.APP_ID = window.location.host;
CONFIG.APP_VERSION = APP_VERSION;
CONFIG.APP_PLATFORM = 'webapp';

CONFIG.AUTH_QUERY = `scope=${CONFIG.SCOPE}&appId=${CONFIG.APP_ID}&appVersion=${CONFIG.APP_VERSION}&appPlatform=${CONFIG.APP_PLATFORM}`;

module.exports = Object.freeze(CONFIG);
