const CONST = require('./const');
const LANG = require('./lang');

const SHARED = {};

SHARED.ORDER_CANCEL_REASON_SET = [
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.NONE],
    value: CONST.ORDER_CANCEL_REASON.NONE,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.SKIRMISH],
    value: CONST.ORDER_CANCEL_REASON.SKIRMISH,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.SERVICE_NOT_NEEDED],
    value: CONST.ORDER_CANCEL_REASON.SERVICE_NOT_NEEDED,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.SERVICE_DURATION],
    value: CONST.ORDER_CANCEL_REASON.SERVICE_DURATION,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.SERVICE_COST],
    value: CONST.ORDER_CANCEL_REASON.SERVICE_COST,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.TAXI_MISSING],
    value: CONST.ORDER_CANCEL_REASON.TAXI_MISSING,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.TAXI_URGENCY],
    value: CONST.ORDER_CANCEL_REASON.TAXI_URGENCY,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.TAXI_FAILURE],
    value: CONST.ORDER_CANCEL_REASON.TAXI_FAILURE,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.TAXI_CRASH],
    value: CONST.ORDER_CANCEL_REASON.TAXI_CRASH,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.CLIENT_NOT_FOUND],
    value: CONST.ORDER_CANCEL_REASON.CLIENT_NOT_FOUND,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.CLIENT_MISSING],
    value: CONST.ORDER_CANCEL_REASON.CLIENT_MISSING,
  },
  {
    label: LANG.CURRENT.LABEL.ORDER_CANCEL_REASON[CONST.ORDER_CANCEL_REASON.OTHER],
    value: CONST.ORDER_CANCEL_REASON.OTHER,
  },
];

SHARED.CONNECTED_CLASS_SET = {
  true: {
    label: 'Connecté',
    icon: 'device:signal-cellular-4-bar',
    class: '-text-positive',
  },
  false: {
    label: 'Déconnecté',
    icon: 'device:signal-cellular-off',
    class: '-text-negative',
  },
};

SHARED.TAXI_SUBSCRIPTION_TYPE_CLASS_SET = {
  premium: {
    label: 'Premium',
    icon: 'editor:attach-money',
    class: '-text-positive',
  },
  freemium: {
    label: 'Freemium',
    icon: 'editor:money-off',
    class: '-text-negative',
  },
};

SHARED.VERIFIED_CLASS_SET = {
  true: {
    label: 'verifié',
    icon: 'verified-user',
    class: '-text-positive',
  },
  false: {
    label: 'non-verifié',
    icon: 'indeterminate-check-box',
    class: '-text-negative',
  },
};

module.exports = Object.freeze(SHARED);
