Polymer({
  is: 'taxi-transaction-form',

  _entity: 'transaction',

  behaviors: [global.App.ElementBase],

  properties: {
    taxi: {
      type: String,
      notify: true,
    },
    item: {
      type: Object,
      value() {
        return {
          type: global.App.CONST.TRANSACTION_TYPE.PAYMENT,
        };
      },
    },
    transactionTypeSet: {
      type: Array,
      readOnly: true,
      value: [global.App.CONST.TRANSACTION_TYPE.PAYMENT, global.App.CONST.TRANSACTION_TYPE.ADJUSTMENT],
    },
  },

  do() {
    const body = this.$$('#form').serialize();
    const path = `/admin/taxi/${this.taxi}/balance/${this.item.type}`;

    global.App.fetch(path, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        global.App.EVENT.emit('transaction:add', response.transaction);
        global.App.EVENT.emit('item:update', response.balance);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
