global.App.UI = {
  activityCount: 0,

  showActivity() {
    this.activityCount++;
  },

  clearActivity() {
    if (this.activityCount) {
      this.activityCount--;
    }
  },

  showToast(message, options) {
    options = options || {};
    options.duration = options.duration || 8000;

    if (options.action) {
      this.set('toastActionLabel', options.actionLabel);
      this._toastActionCallback = options.action || function () {};
      this.$.toastWidthAction.show({
        text: message,
        duration: options.duration,
      });
    } else {
      this.$.toast.show({
        text: message,
        duration: options.duration,
      });
    }
  },

  closeActionToast(event) {
    this.$.toastWidthAction.close();
    this._toastActionCallback();
  },

  closeMenu() {
    this.$.menuDrawer.closeDrawer();
  },

  openMenu() {
    this.$.menuDrawer.openDrawer();
  },

  toggleMenu() {
    this.$.menuDrawer.togglePanel();
  },

  openSideView() {
    this.$.sideViewDrawer.openDrawer();
    // this.$.sideViewDrawer.forceNarrow = false;
  },

  closeSideView() {
    this.$.sideViewDrawer.closeDrawer();
    // this.$.sideViewDrawer.forceNarrow = true;
  },

  backSideView() {
    if (this.$.state.history.length) {
      this.$.state.back();
    } else {
      this.closeSideView();
    }
  },

  scrollMainViewToTop: function scrollPageToTop() {
    this.$.headerPanelMain.scrollToTop(true);
  },

  scrollSubViewToTop: function scrollPageToTop() {
    // @TODO implement
  },

  fireRefreshAction() {
    this.fire('iron-signal', {
      // @DEPRECATE
      name: 'refresh-action',
    });

    global.App.EVENT.emit('action:refresh');
  },

  fireSearchAction() {
    if (!this.$.searchInput) {
      this.$.searchInput = window.searchInput;
    }
    const query = this.$.searchInput.value;
    this.fire('iron-signal', {
      // @DEPRECATE
      name: 'search-action',
      data: {
        item: query,
      },
    });

    global.App.EVENT.emit('item:search', {
      item: query,
    });
  },

  // _goToTargetState: function (event, detail) {
  //   var keycombo = event.detail.combo;
  //   debug("key", keycombo);
  //   if (a11yKeysSet[keycombo] === '_search') {
  //     this.$.searchInput.focus();
  //     return;
  //   }
  //   this.$.state.goTo(a11yKeysSet[keycombo]);
  // },

  _openSearchSide(event, detail) {
    // debug('_openSearchSide', this.$.searchInput.value)
    this.$.state.goTo(`${this.$.state.name}.search`);
    this.fire('iron-signal', {
      // @DEPRECATE
      name: 'search-action',
      data: {
        item: this.$.searchInput.value,
      },
    });
  },

  _errorHandler(event, detail) {
    console.error('_errorHandler', arguments);
    this.showToast(detail.message);
  },
};
