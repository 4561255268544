const SMS_STATE_SET = {
  initiated: {
    label: "echec d'envoie",
    icon: 'notification:sms',
    class: '-text-muted',
  },
  sent: {
    label: "succée d'envoie",
    icon: 'notification:sms',
    class: '-text-positive',
  },
  received: {
    label: 'succée de reception',
    icon: 'notification:sms',
    class: '-color-dark-green',
  },
  failed: {
    label: "echec d'envoie",
    icon: 'notification:sms-failed',
    class: '-text-negative',
  },
  ignored: {
    label: 'SMS igonré',
    icon: 'notification:sms-failed',
    class: '-text-muted',
  },
};

Polymer({
  is: 'sms-item',

  properties: {
    item: {
      type: Object,
    },
    smsStateSet: {
      type: Object,
      notify: true,
      value() {
        return SMS_STATE_SET;
      },
    },
  },

  behaviors: [global.App.ElementBase],

  _resendSMS() {
    const { item } = this;
    global.App.socketio.emit(
      '/admin/sms/:smsId/resend',
      {
        smsId: item.id,
      },
      (error, result) => {
        if (error) {
          this.fire('failure', error);
          return;
        }
        result = {
          ignoredSMS: item,
          done: result.done,
        };
        this.fire('resend', result);
      },
    );
  },

  _ignoreSMS() {
    const { item } = this;
    global.App.socketio.emit(
      '/admin/sms/:smsId/ignore',
      {
        smsId: item.id,
      },
      (error, result) => {
        if (error) {
          this.fire('failure', error);
          return;
        }
        this.fire('ignore', result.notification);
      },
    );
  },
});

Polymer({
  is: 'sms-manual-list-element',

  _entity: 'notification',

  behaviors: [global.App.ListViewBase],

  properties: {
    smsCount: {
      type: Number,
      notify: true,
    },
  },

  observers: ['_countSMS(items)'],

  attached() {
    this._fetchItems();
  },

  _load() {
    this._fetchItems();
  },

  _search(event, detail) {
    if (!detail || !detail.item) {
      return;
    }
    this._fetchItems(detail.item);
  },

  _fetchItems(q) {
    const options = {
      query: {
        filterBy: {
          notification: {
            type: global.App.CONST.NOTIFICATION_TYPE.SMS,
            usage: global.App.CONST.NOTIFICATION_USAGE.MANUAL,
          },
        },
      },
    };

    if (q) {
      options.query.q = q;
    }

    this._getItems('/admin/notification', options);
  },

  ready() {
    global.App.socketio.on('admin:sms:message', (payload) => {
      const item = payload.notification;
      if (item.usage !== global.App.CONST.NOTIFICATION_USAGE.MANUAL || item.ignored) {
        return;
      }
      const index = this._getIndex(this.items, item);
      if (index > -1) {
        this.set(`items.${index}`, item);
      } else {
        this.unshift('items', item);
        this._countSMS(this.items);
      }
    });
  },

  _ignoreSMS(event, detail) {
    const item = detail;
    const message = 'Le SMS est bien archivé!';
    this._deleteItem(item, message);
  },

  _resendSMS(event, detail) {
    const item = detail.ignoredSMS;
    const message = 'Le SMS est bien re-envoyé!';
    this._deleteItem(item, message);
  },

  _deleteItem(item, message) {
    const index = this._getIndex(this.items, item);
    if (index > -1) {
      this.splice('items', index, 1);
      this._countSMS(this.items);
      app.showToast(message);
    }
  },

  _countSMS(items) {
    this.set('smsCount', items.length);
  },
});

Polymer({
  is: 'sms-order-list-element',

  _entity: 'notification',

  behaviors: [global.App.ListViewBase],

  properties: {
    smsCount: {
      type: Number,
      notify: true,
    },
  },

  observers: ['_countSMS(items)'],

  attached() {
    this._fetchItems();
  },

  _load() {
    this._fetchItems();
  },

  _search(event, detail) {
    this._fetchItems(detail.item);
  },

  _fetchItems(q) {
    const options = {
      query: {
        filterBy: {
          notification: {
            type: global.App.CONST.NOTIFICATION_TYPE.SMS,
            usage: global.App.CONST.NOTIFICATION_USAGE.ORDER_IMMEDIATE,
          },
        },
      },
    };

    if (q) {
      options.query.q = q;
    }

    this._getItems('/admin/notification', options);
  },

  ready() {
    global.App.socketio.on('admin:sms:message', (payload) => {
      const item = payload.notification;
      if (item.usage !== global.App.CONST.NOTIFICATION_USAGE.IMMEDIATE) {
        return;
      }

      const index = this._getIndex(this.items, item);

      if (index > -1) {
        this.set(`items.${index}`, item);
      } else {
        this.unshift('items', item);
        this._countSMS(this.items);
      }
    });
  },

  _ignoreSMS(event, detail) {
    const item = detail;
    const message = 'Le SMS est bien archivé!';
    this._deleteItem(item, message);
  },

  _resendSMS(event, detail) {
    const item = detail.ignoredSMS;
    const message = 'Le SMS est bien re-envoyé!';
    this._deleteItem(item, message);
  },

  _deleteItem(item, message) {
    const index = this._getIndex(this.items, item);
    if (index > -1) {
      this.splice('items', index, 1);
      this._countSMS(this.items);
      app.showToast(message);
    }
  },

  _countSMS(items) {
    this.set('smsCount', items.length);
  },
});

Polymer({
  is: 'sms-booking-list-element',

  _entity: 'notification',

  behaviors: [global.App.ListViewBase],

  properties: {
    smsCount: {
      type: Number,
      notify: true,
    },
  },

  observers: ['_countSMS(items)'],

  attached() {
    this._fetchItems();
  },

  _load() {
    this._fetchItems();
  },

  _search(event, detail) {
    if (!detail || !detail.item) {
      return;
    }
    this._fetchItems(detail.item);
  },

  _fetchItems(q) {
    const options = {
      query: {
        filterBy: {
          notification: {
            type: global.App.CONST.NOTIFICATION_TYPE.SMS,
            usage: global.App.CONST.NOTIFICATION_USAGE.ORDER_BOOKING,
          },
        },
      },
    };

    if (q) {
      options.query.q = q;
    }

    this._getItems('/admin/notification', options);
  },

  ready() {
    global.App.socketio.on('admin:sms:message', (payload) => {
      const item = payload.notification;
      if (item.usage !== global.App.CONST.NOTIFICATION_USAGE.BOOKING) {
        return;
      }
      const index = this._getIndex(this.items, item);
      if (index > -1) {
        this.set(`items.${index}`, item);
      } else {
        this.unshift('items', item);
        this._countSMS(this.items);
      }
    });
  },

  _ignoreSMS(event, detail) {
    const item = detail;
    const message = 'Le SMS est bien archivé!';
    this._deleteItem(item, message);
  },

  _resendSMS(event, detail) {
    const item = detail.ignoredSMS;
    const message = 'Le SMS est bien re-envoyé!';
    this._deleteItem(item, message);
  },

  _deleteItem(item, message) {
    const index = this._getIndex(this.items, item);
    if (index > -1) {
      this.splice('items', index, 1);
      this._countSMS(this.items);
      app.showToast(message);
    }
  },

  _countSMS(items) {
    this.set('smsCount', items.length);
  },
});

Polymer({
  is: 'map-sms-view',

  behaviors: [global.App.FormViewBehavior, global.App.ListViewBase],

  properties: {
    selectedState: {
      type: String,
      value() {
        return 'manual-sms';
      },
    },
    manualSMSCount: {
      type: Number,
      notify: true,
      value: '...',
    },
    autoSMSCount: {
      type: Number,
      notify: true,
      value: '...',
    },
    bookingSMSCount: {
      type: Number,
      notify: true,
      value: '...',
    },
  },

  _sendSMS() {
    this.$.form._do();
  },

  _successHandler(event, detail) {
    const response = detail;
    const message = response ? 'Le SMS a été bien initié!' : "Echec d'envoi de SMS";
    app.showToast(message);
  },

  _failureHandler(event, detail) {
    app.showToast(detail);
  },
});
