const CONST = require('~/common/const');
const LANG = require('~/common/lang');

const PRESET = {};

PRESET[CONST.ORDER_SUBTYPE.IMMEDIATE] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.IMMEDIATE],
  type: CONST.ORDER_TYPE.IMMEDIATE,
  subtype: CONST.ORDER_SUBTYPE.IMMEDIATE,
  billingMode: CONST.ORDER_BILLING_MODE.METER,
  costOfService: CONST.COST_OF_SERVICE.IMMEDIATE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.IMMEDIATE_INCLUSIVE_CASH] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.IMMEDIATE_INCLUSIVE_CASH],
  type: CONST.ORDER_TYPE.IMMEDIATE,
  subtype: CONST.ORDER_SUBTYPE.IMMEDIATE_INCLUSIVE_CASH,
  billingMode: CONST.ORDER_BILLING_MODE.INCLUSIVE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.IMMEDIATE_INCLUSIVE_POSTPAID] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.IMMEDIATE_INCLUSIVE_POSTPAID],
  type: CONST.ORDER_TYPE.IMMEDIATE,
  subtype: CONST.ORDER_SUBTYPE.IMMEDIATE_INCLUSIVE_POSTPAID,
  billingMode: CONST.ORDER_BILLING_MODE.INCLUSIVE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.POSTPAID,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING,
  billingMode: CONST.ORDER_BILLING_MODE.SERVICE,
  costOfService: CONST.COST_OF_SERVICE.BOOKING,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING_INCLUSIVE_CASH] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_INCLUSIVE_CASH],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_INCLUSIVE_CASH,
  billingMode: CONST.ORDER_BILLING_MODE.INCLUSIVE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING_INCLUSIVE_POSTPAID] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_INCLUSIVE_POSTPAID],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_INCLUSIVE_POSTPAID,
  billingMode: CONST.ORDER_BILLING_MODE.INCLUSIVE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.POSTPAID,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_TRANSFER,
  billingMode: CONST.ORDER_BILLING_MODE.SERVICE,
  costOfService: CONST.COST_OF_SERVICE.BOOKING_TRANSFER,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER_INCLUSIVE_CASH] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER_INCLUSIVE_CASH],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_TRANSFER_INCLUSIVE_CASH,
  billingMode: CONST.ORDER_BILLING_MODE.INCLUSIVE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER_INCLUSIVE_POSTPAID] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER_INCLUSIVE_POSTPAID],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_TRANSFER_INCLUSIVE_POSTPAID,
  billingMode: CONST.ORDER_BILLING_MODE.INCLUSIVE,
  paymentMode: CONST.ORDER_PAYMENT_MODE.POSTPAID,
});

/*
PRESET[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_TRANSFER,
  billingMode: CONST.ORDER_BILLING_MODE.SERVICE,
  costOfService: CONST.COST_OF_SERVICE.BOOKING_TRANSFER,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});

PRESET[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER] = Object.freeze({
  label: LANG.CURRENT.LABEL.ORDER_SUBTYPE[CONST.ORDER_SUBTYPE.BOOKING_TRANSFER],
  type: CONST.ORDER_TYPE.BOOKING,
  subtype: CONST.ORDER_SUBTYPE.BOOKING_TRANSFER,
  billingMode: CONST.ORDER_BILLING_MODE.SERVICE,
  costOfService: CONST.COST_OF_SERVICE.BOOKING_TRANSFER,
  paymentMode: CONST.ORDER_PAYMENT_MODE.CASH,
});
*/

module.exports = Object.freeze(PRESET);
