Polymer({
  is: 'sms-form',

  behaviors: [global.App.FormElementBehavior],

  properties: {
    user: {
      type: Object,
      notify: true,
      value() {
        return null;
      },
    },
    sms: {
      type: Object,
      notify: true,
      value() {
        return {
          // recipient: '',
          // message: ''
        };
      },
    },
  },

  _fetchUserOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/user',
      item: 'user',
      key: 'name',
    });
  },

  _transformUser(user) {
    return {
      user,
    };
  },

  _do() {
    this.sms.recipient = this.user.id;

    const payload = {
      sms: this.sms,
    };

    global.App.socketio.emit('/admin/sms/send', payload, (error, result) => {
      if (error) {
        this.fire('failure', error);
        return;
      }
      this.fire('success', result.done);
      this.set('user', null);
      this.set('sms', {});
    });
  },
});
