Polymer({
  is: 'order-by-taxi-report-view',

  _entity: 'data',

  behaviors: [global.App.ListViewBase],

  observers: ['_loadFetchedItems(dateRange.*)'],

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      debug('date:change:connectivity-taxi-report-view', response);

      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });
  },

  attached() {
    const date = {
      from: JSON.parse(window.localStorage.getItem('etaxi.dateFrom')),
      until: JSON.parse(window.localStorage.getItem('etaxi.dateUntil')),
    };
    debug('date', date);
    this.set('dateRange', date);
    this._loadFetchedItems(this.dateRange);
  },

  _loadFetchedItems(_from, _until) {
    this._fetchItems('/admin/statistics/order-by-taxi-report', {
      from: this.dateRange.from,
      until: this.dateRange.until,
    });
  },
});
