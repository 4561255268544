const FILTER_BOOKING_SET = {
  state: [
    {
      value: global.App.CONST.ORDER_STATE.PENDING,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[global.App.CONST.ORDER_STATE.PENDING],
      checked: true,
    },
    {
      value: global.App.CONST.ORDER_STATE.ATTRIBUTED,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[global.App.CONST.ORDER_STATE.ATTRIBUTED],
      checked: false,
    },
  ],
};

const FILTER_BOOKING_INITIAL = {
  state: FILTER_BOOKING_SET.state.map((item) => item.value),
  type: global.App.CONST.ORDER_TYPE.BOOKING,
};

const BOOKING_IGNORED_STATE = [global.App.CONST.ORDER_STATE.CANCELLED];

Polymer({
  is: 'map-booking-followup-view',

  _entity: 'home',

  behaviors: [global.App.ListViewBase],

  properties: {
    selectedItem: {
      type: Object,
      notify: true,
    },
    filterBySet: {
      type: Array,
      value: FILTER_BOOKING_SET,
    },
    filterBy: {
      type: Object,
      notify: true,
      value: FILTER_BOOKING_INITIAL,
    },
  },

  observers: ['_fetchByState(filterBy.state.*)'],

  _fetchByState(filterBy) {
    this._fetchItems({
      filterBy: this.filterBy,
    });
  },

  _fetchItems(fetchOptions) {
    const TEN_DISTANCE = 15 * 60 * 1000; // 30 min [ms]

    const now = new Date();
    const start = new Date(now.getTime() - TEN_DISTANCE);
    const end = new Date(start.getTime() + global.App.CONST.DISTANCE);

    const options = {
      entity: 'booking',
      query: {
        filterBy: {
          requestedAt: {
            '>=': start,
            '<': end,
          },
        },
        sortedBy: 'requestedAt ASC',
      },
    };

    if (fetchOptions.q) {
      options.query.q = fetchOptions.q;
    }

    if (fetchOptions.filterBy.state) {
      options.query.filterBy.state = fetchOptions.filterBy.state;
    }

    this._getItems('/admin/booking', options);
  },

  ready() {
    global.App.socketio.on('admin:booking:state', (payload) => {
      const item = payload.order;
      const index = this._getIndex(this.items, item);

      if (index > -1) {
        if (BOOKING_IGNORED_STATE.indexOf(item.state) !== -1) {
          if (item.state === global.App.CONST.ORDER_STATE.CANCELLED) {
            this._playSoundNotification(global.App.LANG.CURRENT.LABEL.RIDE_NOTIFICATION[item.type][item.state]);
          }
          this.splice('items', index, 1);
          return;
        }

        this.splice('items', index, 1, item);
      } else {
        this.unshift('items', item);

        // @TODO PLAY SOUND_NOTIFICATION on-add-new-booking
        // let text = (item.initiatedBy) ? "une nouvelle réservation" : "une nouvelle réservation par le client";
        this._playSoundNotification(global.App.LANG.CURRENT.LABEL.RIDE_NOTIFICATION[item.type][item.state]);
      }
    });
  },

  attached() {
    global.App.EVENT.on('action:refresh', () => {
      this._fetchByState();
    });
  },
});
