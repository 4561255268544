Polymer({
  is: 'checkbox-group',

  behaviors: [global.App.ElementBase],

  properties: {
    selectedItems: {
      type: Array,
      notify: true,
      value() {
        return [];
      },
    },
    selectedKey: {
      type: String,
      notify: true,
      // readOnly: true,
    },
    options: {
      type: Array,
      notify: true,
    },
    _options: {
      type: Array,
      notify: true,
      value() {
        return [];
      },
    },
  },

  observers: ['_optionsChanged(options)', '_valueChanged(selectedItems)', '_checkboxChanged(_options.*)'],

  _optionsChanged() {
    // debug('checkbox-group._optionsChanged', this.options);
    this._options = this.options.map((option) => ({ ...option }));
  },

  _valueChanged() {
    // debug('checkbox-group._valueChanged', this.selectedItems, this.options);
    // this._value = this.selectedItems;
    // @TODO fix this
    // if (!this.selectedItems) // return; // this._options.forEach(function(v) { // v.checked = this.selectedItems.indexOf(v.value) !== -1 // }.bind(this));
  },

  _checkboxChanged() {
    // debug('checkbox-group._checkboxChanged', this._value);
    this.selectedItems = this._value;
  },

  get _value() {
    return this._options.filter((v) => v.checked).map((v) => v.value);
  },

  _computeValue(value, key) {
    return value[key];
  },

  // set _value(array) {
  //   this._options.forEach((v) => {
  //     v.checked = array.indexOf(v.value) !== -1
  //   });
  // },
});
