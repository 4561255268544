Polymer({
  is: 'password-form',

  behaviors: [global.App.FormElementBehavior],

  properties: {
    url: {
      type: String,
    },
    password: {
      type: Object,
      notify: true,
      value() {
        return {};
      },
    },
  },
});
