Polymer({
  is: 'paginator-display',

  behaviors: [global.App.ElementBase],

  properties: {
    /**
     * @attribute pageSize
     * @type number
     */
    pageSize: {
      type: Number,
      notify: true,
      value: global.App.CONST.PAGINATE_INITIAL.pageSize,
    },
    /**
     * @attribute count
     * @type number
     */
    count: {
      type: Number,
      // readOnly: true,
      notify: true,
      value: global.App.CONST.PAGINATE_INITIAL.count,
    },
    /**
     * @attribute pages
     * @type number
     */
    pages: {
      type: Number,
      notify: true,
      computed: '_computedPages(pageSize,count)',
    },
    /**
     * @attribute page
     * @type number
     */
    page: {
      type: Number,
      notify: true,
      reflectToAttribute: true,
      value: global.App.CONST.PAGINATE_INITIAL.page,
    },
  },

  /**
   * compute total pages
   *
   * @method _computedPages
   */
  _computedPages(pageSize, count) {
    const pages = Math.ceil(count / pageSize);
    return pages || '?';
  },

  /**
   * navigate to the next page
   *
   * @method _goNextPage
   */
  _goNextPage() {
    const page = this.page + 1;
    this.set('page', page);

    this.fire('paginate-next', {
      page,
    });
  },

  /**
   * navigate to the previouse page
   *
   * @method _goPreviousePage
   */
  _goPreviousePage() {
    const page = this.page - 1;
    this.set('page', page);

    this.fire('paginate-previouse', {
      page,
    });
  },

  /**
   * navigate to the first page
   *
   * @method _goFirstPage
   */
  _goFirstPage() {
    this.set('page', 1);

    this.fire('paginate-first', {
      page: this.page,
    });
  },

  /**
   * navigate to the last page
   *
   * @method _goLastPage
   */
  _goLastPage() {
    this.set('page', this.pages);

    this.fire('paginate-last', {
      page: this.page,
    });
  },

  _pageSizeChanged() {
    this.fire('pagesize-changed', {
      pageSize: this.pageSize,
    });
  },
});
