Polymer({
  is: 'map-order-timedout-view',

  _entity: 'home',

  behaviors: [global.App.ListViewBase],

  properties: {
    filterBy: {
      type: Object,
      notify: true,
      value: global.App.CONST.ORDER_STATE.TIMEDOUT,
    },
  },

  observers: ['_fetchItems(filterBy)'],

  _fetchItems(filterBy) {
    const end = new Date();
    const start = new Date(end.getTime() - global.App.CONST.DISTANCE);

    const options = {
      entity: 'order',
      query: {
        filterBy: {
          state: global.App.CONST.ORDER_STATE.TIMEDOUT,
          initiatedAt: {
            '>=': start,
            '<': end,
          },
          type: global.App.CONST.ORDER_TYPE.IMMEDIATE,
        },
      },
    };

    this._getItems('/admin/order', options);
  },

  ready() {
    global.App.EVENT.on('action:refresh', () => {
      this._fetchItems(this.filterBy);
    });

    global.App.socketio.on('admin:order:state', (payload) => {
      const item = payload.order;

      debug('XXXX -timedout', item);

      if (item.type !== global.App.CONST.ORDER_TYPE.IMMEDIATE) {
        return;
      }

      const index = item.state === global.App.CONST.ORDER_STATE.TIMEDOUT || item.state === global.App.CONST.ORDER_STATE.PENDING
        ? this._getTimedoutIndex(this.items, item)
        : this._getIndex(this.items, item);

      if (index > -1) {
        if (item.state !== global.App.CONST.ORDER_STATE.TIMEDOUT) {
          this.splice('items', index, 1);
        }
      } else {
        if (item.state !== global.App.CONST.ORDER_STATE.TIMEDOUT) {
          return;
        }

        // PLAY SOUND_NOTIFICATION
        this._playSoundNotification(global.App.LANG.CURRENT.LABEL.RIDE_NOTIFICATION[item.type][item.state]);

        this.unshift('items', item);
      }
    });
  },

  _showDetailMap(event, detail) {
    const { item } = detail;
    global.App.EVENT.emit('map:order:itinerary', {
      requestPosition: item.requestAddress.position,
      destinationPosition: item.destinationAddress.position,
      startPosition: item.startPosition,
      route: item.route,
      taxi: item.taxi,
    });
  },
});
