global.App.RequestUtils = {
  properties: {
    items: {
      type: Array,
      notify: true,
      value() {
        return [];
      },
    },
    requestPosition: {
      type: Object,
      notify: true,
    },
    destinationPosition: {
      type: Object,
      notify: true,
    },
    startPosition: {
      type: Object,
      notify: true,
    },
    search: {
      type: String,
      notify: true,
    },
    searchResults: {
      type: Array,
      notify: true,
    },
    itinerary: {
      type: Object,
      notify: true,
    },
  },

  _cancelRequest() {
    this.set('requestPosition', null);
    this.set('destinationPosition', null);
  },
};
