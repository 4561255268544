Polymer({
  is: 'taxi-tracking-view',

  behaviors: [global.App.ListViewBase, global.App.MapHelpers],

  properties: {
    driver: {
      type: String,
      notify: true,
    },
    from: {
      type: Date,
      notify: true,
      value() {
        return global.App.moment()
          .startOf('hour')
          .subtract(2, 'hour')
          .format('YYYY-MM-DDTHH:mm');
      },
    },
    until: {
      type: Date,
      notify: true,
      value() {
        return global.App.moment()
          .startOf('hour')
          .format('YYYY-MM-DDTHH:mm');
      },
    },
    loaded: {
      type: Boolean,
      value: true,
    },
  },

  observers: ['_validateDate(from, until)'],

  _validateDate(from, until) {
    if (from > until) {
      from = global.App.moment(until)
        .startOf('hour')
        .subtract(2, 'hour')
        .format('YYYY-MM-DDTHH:mm');
      this.set('from', from);
    }
  },

  _computedMarkerTitle(index, timestamp) {
    return `${index} , ${this.$date(timestamp, 'DD MMM Y à HH:mm')}`;
  },

  _fetchDriverOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/driver',
      item: 'driver',
      key: 'name',
    });
  },

  _trackTaxi() {
    this.set('items', null);
    this.set('loaded', false);

    global.App.fetch(`/admin/taxi/${this.driver.taxi.id}/track`, {
      query: {
        from: new Date(this.from.replace('T', ' ')),
        until: new Date(this.until.replace('T', ' ')),
      },
    })
      .then((response) => {
        this.set('loaded', true);
        this.set('items', response.taxiPositionHistory);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
