global.App.Auth = {
  authenticated: false,

  isAuthenticated() {
    return !!this.token && this.authenticated;
  },

  onUnauthenticatedAccess(event, detail) {
    this.logout('Session expirée!');
  },

  onUnauthorizedAccess(event, detail) {
    this.showToast('Unauthorized access!');
    this.$.state.goTo('home');
  },

  _loginSuccess(event, detail) {
    this._login(detail);
  },

  _login(response) {
    // debug('detail.._loginSuccess', response);
    this.set('user', response.user);
    this.set('token', response.token);
    this.set('authenticated', true);

    // this.showToast('Welcome back ' + this.user.name + ' !');
    this.$.state.goTo('home');
    this.setupConnection();
  },

  logout: function logout(message) {
    // debug('currentOrder', this.$.currentOrder.value, this.$.currentOrder);

    if (typeof message !== 'string') {
      message = 'A bientôt!';
    }

    global.App.socketio.disconnect();

    this.user = null;
    this.token = null;
    this.authenticated = false;
    this.$.state.clear();

    const tmp = {};
    tmp['etaxi.authCredentials'] = window.localStorage['etaxi.authCredentials'];
    tmp.debug = window.localStorage.debug;
    window.localStorage.clear();
    Object.assign(window.localStorage, tmp);

    this.$.state.goTo('login');
    this.showToast(message);
  },

  userLoad() {
    this.user = null;
  },

  tokenLoad() {
    this.token = null;
  },
};
