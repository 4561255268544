<link rel="import" href="../bower_components/polymer/polymer.html" />

<link rel="import" href="../bower_components/iron-signals/iron-signals.html" />

<script>
  function getToken() {
    return global.app ? global.app.token : null;
  }
</script>

<link rel="import" href="../bower_components/iron-ajax/iron-ajax.html" />

<script>
  (function() {
    var IronAjax = document.createElement('iron-ajax').constructor;
    // send as JSON by default
    IronAjax.prototype.properties.contentType.value = 'application/json';

    // attach JWT token to Authorization header when available
    IronAjax.prototype.properties.headers.value = function() {
      var headers = {
        'X-Requested-With': 'XMLHttpRequest',
      };
      if (getToken()) {
        headers.Authorization = 'Bearer ' + getToken();
      }
      return headers;
    };

    // handle 401 and 403 responses globally
    IronAjax.prototype.created = function() {
      this.addEventListener('request', function() {
        if (global.app) {
          global.app.showActivity();
        }
      });
      this.addEventListener('response', function() {
        if (global.app) {
          global.app.clearActivity();
        }
      });
      this.addEventListener('error', function(event) {
        debug('iron-ajax', 'global-error', arguments);
        if (global.app) {
          global.app.clearActivity();
          if (event.detail.request.status === 401 || event.detail.request.status === 403) {
            global.app.fire('iron-signal', {
              // @DEPRECATE
              name: event.detail.request.status === 401 ? 'unauthenticated' : 'unauthorized',
              data: {
                request: event.detail.request,
                error: event.detail.error,
              },
            });
            event.stopImmediatePropagation();
          }
        }
      });
    };

    // overwrite queryString
    function urlEncodeObject(result, name, value) {
      if (Array.isArray(value)) {
        value.forEach(function(val, index) {
          urlEncodeObject(result, name + '[' + index + ']', val);
        });
      } else if (typeof value === 'object' && value) {
        Object.keys(value).forEach(function(key) {
          urlEncodeObject(result, name + '[' + key + ']', value[key]);
        });
      } else {
        result[name] = value;
      }
    }
    Object.defineProperty(IronAjax.prototype, 'queryString', {
      get: function queryString() {
        var queryParts = {};
        var param;
        var value;
        for (param in this.params) {
          value = this.params[param];
          urlEncodeObject(queryParts, param, value);
        }
        return Object.keys(queryParts)
          .map(function(param) {
            value = queryParts[param];
            return param + '=' + window.encodeURIComponent(value);
          })
          .join('&');
      },
    });
  })();
</script>

<link rel="import" href="../bower_components/iron-form/iron-form.html" />

<script>
  (function() {
    try {
      var IronForm = document.createElement('form', 'iron-form').constructor;
      // send as JSON by default
      IronForm.prototype.properties.contentType.value = 'application/json';
      // attach JWT token to Authorization header when available
      IronForm.prototype.properties.headers.value = function() {
        var headers = {
          'X-Requested-With': 'XMLHttpRequest',
        };
        if (getToken()) {
          headers.Authorization = 'Bearer ' + getToken();
        }
        return headers;
      };
    } catch (e) {}
  })();
</script>
