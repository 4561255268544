// eslint-disable-next-line global-require
const CONST = { ...require('etaxi-lib/src/common/const') };

CONST.PAGINATE_INITIAL = {
  pageSize: 50,
  page: 1,
  pages: '?',
  count: '?',
};

CONST.TRANSACTION_TYPE = CONST.constantify(['summary', 'subscription', 'payment', 'adjustment']);

CONST.NOTIFICATION_STATE = CONST.constantify([
  'initiated', // notification iniated
  'sent', // notification has been sent
  'received', // notivication has been received
  'failed', // notivication sending has failed
]);

CONST.NOTIFICATION_TYPE = CONST.constantify(['email', 'sms', 'push', 'socket']);

CONST.NOTIFICATION_USAGE = CONST.constantify(CONST.ORDER_TYPE.concat(['manual', 'auth', 'taxi']));

CONST.NOTIFICATION_STATE = CONST.constantify([
  'initiated', // notification iniated
  'sent', // notification has been sent
  'received', // notivication has been received
  'failed', // notivication sending has failed
]);

CONST.NOTIFICATION_TYPE = CONST.constantify(['email', 'sms', 'push', 'socket']);

CONST.TAXI_SUBSCRIPTION_TYPE = CONST.constantify(['freemium', 'premium']);

CONST.ROUTE_COLOR = '#137FB4'; // #00B3FD #137FB4
CONST.TAXI_ROUTE_COLOR = '#FAB216'; // #FFD008 #FAB216
CONST.APPROXIMATIVE_LOCATION_FILL_COLOR = '#666666';
CONST.APPROXIMATIVE_LOCATION_STROKE_COLOR = '#333333';

CONST.DISTANCE = 36 * 60 * 60 * 1000; // 36 heures [ms]

module.exports = Object.freeze(CONST);
