Polymer({
  is: 'map-order-request-view',

  _entity: 'client',

  behaviors: [global.App.DetailViewBehavior, global.App.MapHelpers],

  properties: {
    orderConfigSet: {
      type: Array,
      readOnly: true,
      value() {
        return Object.keys(global.App.PRESET).map((key) => global.App.PRESET[key]);
      },
    },
    selectedState: {
      type: String,
      value: 'order',
    },
    selectDriverMode: {
      type: String,
      value: 'auto',
    },
    selectedTaxi: {
      type: Object,
      notify: true,
    },
    passenger: {
      type: Boolean,
      notify: true,
      value: false,
    },
    reinitiatedFor: {
      type: Boolean,
      notify: true,
      value: false,
    },
    client: {
      type: Object,
      notify: true,
      value: null,
    },
    clientRecentAddress: {
      type: Array,
      notify: true,
      value: [],
    },
    driver: {
      type: Object,
      notify: true,
      value: null,
    },
    order: {
      type: Object,
      notify: true,
      value() {
        return {
          subtype: global.App.CONST.ORDER_SUBTYPE.IMMEDIATE,
          requestedAt: global.App.moment()
            .startOf('hour')
            .add(1, 'hour')
            .format('YYYY-MM-DDTHH:mm'),
        };
      },
    },
    orderImmediateValue: {
      type: String,
      readOnly: true,
      value: global.App.CONST.ORDER_TYPE.IMMEDIATE,
    },
    orderState: {
      type: String,
      value: global.App.CONST.ORDER_STATE.FINISHED,
    },
    requestAddressApproximative: {
      type: Boolean,
      notify: true,
      value: true,
    },
    destinationAddressApproximative: {
      type: Boolean,
      notify: true,
      value: true,
    },
    saveManually: {
      type: Boolean,
      notify: true,
      value: false,
    },
    createOtherItem: {
      type: Boolean,
      readOnly: true,
      value: false,
    },
    cancelledOrder: {
      type: Object,
      notify: true,
    },
  },

  observers: [
    // '_goFixStartAddress(driver)',
    '_computedDisabled(order.*, client, selectDriverMode)',
    '_computedRequestAddress(order.requestAddress)',
    '_computedDestinationAddress(order.destinationAddress)',
    '_computedRoute(order.requestAddress, order.destinationAddress)',
    '_computedClientAddress(client)',
    '_computedDriver(selectDriverMode)',
  ],

  _computedDriver(selectDriverMode) {
    if (selectDriverMode !== 'auto') {
      return;
    }
    this.set('driver', null);
    debug('_computedDriver', selectDriverMode, this.driver);
  },

  _computedClientAddress(client) {
    if (!client || !client.id) {
      return;
    }

    if (this.$.selectClientDropdown && this.$.selectClientDropdown.options) {
      // close selectClientDropdown
      this.$.selectClientDropdown.options = null;
    }

    global.App.socketio.emit(
      '/admin/address/recent',
      {
        clientId: client.id,
      },
      (error, result) => {
        if (error) {
          return;
        }

        const options = result.address.map((item) => item);

        this.set('clientRecentAddress', options);

        if (!this.order.requestAddress) {
          this.$$('#requestAddressInput').options = options;
        }
      },
    );
  },

  attached() {
    global.App.EVENT.on('order:relaunch', (response) => {
      if (!response) {
        return;
      }
      this._setRetriedOrder(response.item);
    });
  },

  _computeOrderType(subtype) {
    if (!subtype) {
      return undefined;
    }

    return global.App.PRESET[subtype].type;
  },

  _computedSavedOrderTimestamp(subtype, saveManually) {
    return this._computeOrderType(subtype) === global.App.CONST.ORDER_TYPE.BOOKING || saveManually;
  },

  _computedInclusive(subtype) {
    return (
      subtype
      && global.App.PRESET[subtype]
      && global.App.PRESET[subtype].billingMode
      && global.App.PRESET[subtype].billingMode === global.App.CONST.ORDER_BILLING_MODE.INCLUSIVE
    );
  },

  _computeOrderEstimate(order) {
    const { cost } = global.App.EstimateUtils.calculateOrderEstimate(order);
    return this.$currency(cost);
  },

  _computedStyle(saveManually) {
    const className = saveManually ? '-primary-background' : 'text-muted';
    return className;
  },

  _computedRequestAddress(requestAddress) {
    if (!requestAddress) {
      this.set('order.requestAddress', null);
      return;
    }

    global.App.EVENT.emit('map:position:set', {
      position: requestAddress.position,
    });

    global.App.EVENT.emit('map:set', {
      field: 'requestPosition',
      value: requestAddress.position,
    });
  },

  _computedDestinationAddress(destinationAddress) {
    if (!destinationAddress) {
      this.set('order.destinationAddress', null);
      return;
    }

    global.App.EVENT.emit('map:position:set', {
      position: destinationAddress.position,
    });

    global.App.EVENT.emit('map:set', {
      field: 'destinationPosition',
      value: destinationAddress.position,
    });
  },

  _computedRoute(requestAddress, destinationAddress) {
    if (!requestAddress || !destinationAddress) {
      this.set('order.route', null);
      this.notifyPath('order.route');
      return;
    }

    this._requestRoute(requestAddress.position, destinationAddress.position, (error, route) => {
      if (error) {
        app.showToast(error.message);
        return;
      }

      this.set('order.route', route);
      this.notifyPath('order.route');

      global.App.EVENT.emit('map:route:set', {
        value: this.order.route,
      });
    });
  },

  _fixRequestPosition() {
    // global.App.EVENT.emit('map:position:fix', 'requestPosition');
    global.App.EVENT.emit(
      'map:position:fix',
      {
        showNearbyTaxi: true,
      },
      (result) => {
        this._reverseGeocodeAddress(result.position, (error, address) => {
          if (error) {
            app.showToast(error.message);
            return;
          }

          this.set('order.requestAddress', address);
          this.notifyPath('order.requestAddress');
        });
      },
    );
  },

  _fixDestinationPosition() {
    global.App.EVENT.emit('map:position:fix', {}, (result) => {
      this._reverseGeocodeAddress(result.position, (error, address) => {
        if (error) {
          app.showToast(error.message);
          return;
        }

        this.set('order.destinationAddress', address);
        this.notifyPath('order.destinationAddress');
      });
    });
  },

  _computedDisabled(order, client, selectDriverMode) {
    order = order.base;
    const disabled = !!client && !!order && !!order.requestAddress && !!order.destinationAddress;
    this._computedDisabledButton('requestOrderBtn', !disabled);
  },

  _addClient() {
    this.$$('#form').do();
  },

  _setClient(event, detail) {
    const client = { ...detail, ...detail.user };
    this.set('client', client);
    this._goOrderSection();

    if (!client) {
      this.$.addAction.hidden = false;
    } else {
      this.$.addAction.hidden = true;
    }
  },

  _fetchClientOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/client',
      item: 'client',
      key: 'name',
    });
  },

  _inputAddressChanged(event, detail) {
    function _inputAddressChanged() {
      const input = (event.detail.value || '').trim().toLowerCase();

      if (!input) {
        event.target.options = [];
        return;
      }

      global.App.socketio.emit(
        '/shared/maps/address',
        {
          address: input,
        },
        (error, result) => {
          if (error) {
            event.target.options = [];
            return;
          }

          const options = result.map((item) => item);

          event.target.options = this.clientRecentAddress.concat(options) || options;
        },
      );
    }

    this.debounce('_debounceInputAddressChanged', _inputAddressChanged, 800);
  },

  _fetchDriverOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/driver',
      item: 'driver',
      key: 'name',
    });
  },

  _goClientSection() {
    this.set('selectedState', 'add-client');
  },

  _goOrderSection(event, detail) {
    this.set('selectedState', 'order');
  },

  _setRetriedOrder(item) {
    this.set('order', item);

    if (this.order.passengerPhoneSet) {
      this.order.passengerPhoneSet = this.order.passengerPhoneSet.join(',');
      this.notifyPath('order.passengerPhoneSet');
      this.set('passenger', true);
    }

    this.set('destinationAddressApproximative', item.destinationAddress.approximative);
    this.set('requestAddressApproximative', item.requestAddress.approximative);

    this.set('reinitiatedFor', item.id);

    this.set('client', item.client);
  },

  _goMonotoringOrder() {
    this._resetOrder();
    app.$.state.goTo('home');
  },

  _saveOrder(event, detail) {
    const order = { ...this.order };

    order.destinationAddress.approximative = this.destinationAddressApproximative;
    order.requestAddress.approximative = this.requestAddressApproximative;

    if (order.passengerPhoneSet) {
      order.passengerPhoneSet = order.passengerPhoneSet.split(/\D/).filter((phone) => phone.trim());
    }

    if (
      this.orderState === global.App.CONST.ORDER_STATE.FINISHED
      && this._computeOrderType(order.subtype) === global.App.CONST.ORDER_TYPE.BOOKING
    ) {
      order.state = global.App.CONST.ORDER_STATE.ATTRIBUTED;
    } else {
      order.state = this.orderState;
    }

    if (order.state === global.App.CONST.ORDER_STATE.CANCELLED) {
      Object.assign(order, this.cancelledOrder);
    }

    order.requestedAt = new Date(this.order.requestedAt.replace('T', ' '));

    const payload = {
      clientId: this.client.id,
      order,
    };

    if (this.driver) {
      payload.taxiId = this.driver.taxi.id;
    }
    // this._computedDisabledButton('saveOrderBtn', true);

    global.App.socketio.emit('/admin/order/save', payload, (error, result) => {
      // this._computedDisabledButton('requestOrderBtn', false);

      if (error) {
        app.showToast(error.message);
        return;
      }

      app.showToast('Demande enregistrée.');

      this.set('order', { ...result.order });

      this.$$('#processRequestDialog').open();
      app.showToast('Demande enregistrée.');
    });
  },

  _requestOrder(event, detail) {
    const order = { ...this.order };

    order.destinationAddress.approximative = this.destinationAddressApproximative;
    order.requestAddress.approximative = this.requestAddressApproximative;

    if (order.passengerPhoneSet && !Array.isArray(order.passengerPhoneSet)) {
      order.passengerPhoneSet = order.passengerPhoneSet.split(/\D/).filter((phone) => phone.trim());
    }

    if (this.reinitiatedFor) {
      order.reinitiatedFor = this.reinitiatedFor;
    }

    if (this._computeOrderType(order.subtype) === global.App.CONST.ORDER_TYPE.BOOKING && order.requestedAt) {
      order.requestedAt = new Date(this.order.requestedAt.replace('T', ' '));
    }

    if (this._computeOrderType(order.subtype) !== global.App.CONST.ORDER_TYPE.BOOKING && order.requestedAt) {
      delete order.requestedAt;
    }

    const payload = {
      clientId: this.client.id,
      order,
    };

    if (this.driver) {
      payload.taxiId = this.driver.taxi.id;
    }

    this._computedDisabledButton('requestOrderBtn', true);

    const path = this._computeOrderType(order.subtype) === global.App.CONST.ORDER_TYPE.BOOKING
      ? '/admin/booking/request'
      : '/admin/order/request';

    global.App.socketio.emit(path, payload, (error, result) => {
      if (error) {
        this._computedDisabledButton('requestOrderBtn', false);
        app.showToast(error.message);
        return;
      }

      this.set('order', { ...(result.order || result.booking) });

      setTimeout(() => {
        this.$$('#processRequestDialog').open();
      }, 300);
    });
  },

  _computedDisabledButton(buttonId, value) {
    const btn = document.getElementById(buttonId);
    if (!btn) {
      return;
    }
    btn.disabled = value;
  },

  _resetOrder() {
    this._computedDisabledButton('requestOrderBtn', false);

    this.set('order', {
      subtype: global.App.CONST.ORDER_SUBTYPE.IMMEDIATE,
      requestedAt: global.App.moment()
        .startOf('hour')
        .add(1, 'hour')
        .format('YYYY-MM-DDTHH:mm'),
    });

    this.set('destinationAddressApproximative', true);
    this.set('requestAddressApproximative', true);
    this.set('saveManually', false);

    this.set('passenger', false);

    this.set('reinitiatedFor', null);
    this.set('client', null);

    if (this.driver) {
      this.set('selectDriverMode', 'auto');
      this.set('driver', null);
    }

    global.App.EVENT.emit('map:order:reset');
  },

  _setTaxi(event, detail) {
    this.set('selectedTaxi', detail);
    this.selectedState = 'taxi';
    this._fireSignal(detail, 'taxi-nearby-selected');
  },

  _closeSideView(event, detail) {
    app.$.state.goTo('home');
  },

  _reverseGeocodeAddress(position, callback) {
    if (!position) {
      callback();
      return;
    }

    position = this._position(position);

    global.App.socketio.emit(
      '/shared/maps/address',
      {
        latlng: position,
      },
      (error, result) => {
        if (error) {
          callback(error);
          return;
        }
        callback(null, result[0]);
      },
    );
  },
});
