Polymer({
  is: 'registration-validator',

  behaviors: [Polymer.IronValidatorBehavior],

  validate(value) {
    return !value || value.match(/^[0-9]{0,2}-[0-9]{0,2}-[0-9]{0,5}$/);
  },
});

Polymer({
  is: 'registration-input',

  behaviors: [Polymer.IronValidatableBehavior],

  properties: {
    value: {
      notify: true,
      type: String,
    },

    _key1: {
      type: String,
    },

    _key2: {
      type: String,
    },

    _key3: {
      type: String,
    },

    validator: {
      type: String,
      value: 'registration-validator',
    },
  },

  observers: ['_computeValue(_key1,_key2,_key3)'],

  _computeValue(key1, key2, key3) {
    this.value = `${key1.trim()}-${key2.trim()}-${key3.trim()}`;
  },
});
