Polymer({
  is: 'rating-display',

  // behaviors: [
  //   global.App.ElementBase,
  // ],

  properties: {
    icon: {
      type: String,
      notify: true,
      value: 'grade',
    },
    max: {
      type: Number,
      notify: true,
    },
    review: {
      type: Number,
      notify: true,
    },
  },

  _computeArray(max) {
    return new Array(max || 5);
  },

  _computedHighlight(index) {
    return index < this.review ? '-active-heighlight' : '-text-muted';
  },
});
