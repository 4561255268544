Polymer({
  is: 'login-form',

  behaviors: [global.App.ElementBase],

  properties: {},

  do() {
    const body = this.$$('#form').serialize();
    global.App.fetch(`/auth/login?${global.App.CONFIG.AUTH_QUERY}`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.fire('success', response);
        this.issues = {};
      })
      .catch(this._fetchFailureHandler.bind(this));
  },

  authCredentialsLoad() {
    this.authCredentials = {};
  },
});

Polymer({
  is: 'auth-view',

  behaviors: [global.App.ElementBase],

  _doSubmit() {
    this.$.loginForm.do();
  },

  _goResetPassword(event) {
    event.stopPropagation();
    window.open(`${global.App.CONFIG.CLIENT_APP_ENDPOINT}/#!/reset/password`);
  },

  passwordReset(event, detail) {
    this.message = detail;
  },
});
