Polymer({
  is: 'order-by-client-report-view',

  _entity: 'data',

  behaviors: [global.App.ListViewBase],

  observers: ['_loadFetchedItems(dateRange.*)'],

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      debug('date:change:statistics-overview', response);

      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });
  },

  attached() {
    const date = {
      from: JSON.parse(window.localStorage.getItem('etaxi.dateFrom')),
      until: JSON.parse(window.localStorage.getItem('etaxi.dateUntil')),
    };
    debug('date', date);
    this.set('dateRange', date);
    this._loadFetchedItems(this.dateRange);
  },

  _loadFetchedItems(dateRange) {
    this._fetchPaginatedItems('/admin/statistics/order-by-client-report', {
      paginate: this.paginate,
      from: this.dateRange.from,
      until: this.dateRange.until,
    });
    // this._fetchItems('/admin/statistics/order-by-client-report', {
    //   from: this.dateRange.from,
    //   until: this.dateRange.until,
    // });
  },

  _percent(total, value) {
    if (total === 0 || value === 0) {
      return 0;
    }
    if (total === value) {
      return 100;
    }
    return ((value / total) * 100).toFixed(2);
  },
});
