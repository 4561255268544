const CONST = require('~/common/const');

const LANG = require('./base');

LANG.CURRENT.LABEL.ORDER_STATE[CONST.ORDER_IMMEDIATE_STATE.STARTED] = 'Vers le client';

LANG.CURRENT.LABEL.RIDE_NOTIFICATION = {};
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.IMMEDIATE] = {};
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.BOOKING] = {};
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.IMMEDIATE][CONST.ORDER_STATE.PENDING] = 'course immédiate en attente de réponse';
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.IMMEDIATE][CONST.ORDER_STATE.ONGOING] = 'course immédiate en cours';
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.IMMEDIATE][CONST.ORDER_STATE.FINISHED] = 'course immédiate terminée';
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.IMMEDIATE][CONST.ORDER_STATE.TIMEDOUT] = 'course immédiate ratée.';
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.IMMEDIATE][CONST.ORDER_STATE.CANCELLED] = 'course immédiate annulée';
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.BOOKING][CONST.ORDER_STATE.PENDING] = 'réservation en attente de réponse';
LANG.CURRENT.LABEL.RIDE_NOTIFICATION[CONST.ORDER_TYPE.BOOKING][CONST.ORDER_STATE.CANCELLED] = 'réservation annulée';
