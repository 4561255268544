Polymer({
  is: 'taxi-order-list',

  _entity: 'order',

  behaviors: [global.App.ListViewBase],

  properties: {
    taxi: {
      type: String,
      notify: true,
    },
  },

  observers: ['_loadPaginatedItems(taxi,dateRange.*)'],

  _dateFromLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      from: this.$.dateFrom.value,
    });
    this.set('dateRange', dateRange);
  },

  _dateUntilLoad(event, detail) {
    const dateRange = Object.assign(this.dateRange, {
      until: this.$.dateUntil.value,
    });
    this.set('dateRange', dateRange);
  },

  attached() {
    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });

    global.App.EVENT.on('action:refresh', () => {
      this._loadPaginatedItems();
    });
  },

  _paginate(response) {
    if (response.page) {
      const { page } = response;
      this.set('page', page);
    }

    if (response.pageSize) {
      const { pageSize } = response;
      this.set('pageSize', pageSize);
    }

    this._loadPaginatedItems(this.taxi, this.dateRange);
  },

  _computedFetchPaginatedParams(dateRange) {
    dateRange = Object.assign(this.dateRange, dateRange.base);

    const options = {
      paginate: {
        page: this.page,
        pageSize: this.pageSize,
      },
    };

    if (dateRange && dateRange.from) {
      options.from = dateRange.from;
    } else {
      delete options.from;
    }

    if (dateRange && dateRange.until) {
      options.until = dateRange.until;
    } else {
      delete options.until;
    }

    return options;
  },

  _loadPaginatedItems(taxi, dateRange) {
    this.set('loaded', false);

    global.App.fetch(`/admin/taxi/${taxi}/order`, {
      query: this._computedFetchPaginatedParams(dateRange),
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        this._loaded();

        if (!response.meta) {
          return;
        }

        const paginate = response.meta;
        this.set('page', paginate.page);
        this.set('count', paginate.count);
        this.set('pageSize', paginate.pageSize);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _goDetailMap(event, detail) {
    const { item } = detail;

    global.App.EVENT.emit('map:route:draw', {
      requestAddress: item.requestAddress,
      destinationAddress: item.destinationAddress,
      route: item.route,
      startPosition: item.startPosition,
    });

    if (!item.startPosition) {
      return;
    }

    global.App.EVENT.emit('map:markers:draw', {
      markerSet: [
        {
          position: item.startPosition,
        },
      ],
    });
  },
});
