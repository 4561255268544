Polymer({
  is: 'client-detail-view',

  _entity: 'client',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    selectedState: {
      type: String,
      value: 'profile',
    },
  },

  attached() {
    this._fetchItems(`/admin/client/${this.item.id}`);
  },

  _doAddTag() {
    this.$$('#form').do();
  },
});
