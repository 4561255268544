const FILTER_ORDER_SET = {
  subtype: Object.keys(global.App.PRESET).map((key) => global.App.PRESET[key]),
  state: global.App.CONST.ORDER_STATE.filter((state) => state !== global.App.CONST.ORDER_STATE.REINITIATED).map(
    (state) => ({
      value: state,
      label: global.App.LANG.CURRENT.LABEL.ORDER_STATE[state],
    }),
  ),
};

let FILTER_ORDER_INITIAL = { ...FILTER_ORDER_SET };

FILTER_ORDER_INITIAL = {
  state: FILTER_ORDER_INITIAL.state
    .filter(
      (item) => item.value === global.App.CONST.ORDER_STATE.FINISHED
        || item.value === global.App.CONST.ORDER_STATE.ATTRIBUTED
        || item.value === global.App.CONST.ORDER_STATE.CANCELLED,
    )
    .map((item) => item),
  subtype: FILTER_ORDER_INITIAL.subtype
    .filter(
      (item) => item.subtype === global.App.CONST.ORDER_SUBTYPE.IMMEDIATE
        || item.subtype === global.App.CONST.ORDER_SUBTYPE.BOOKING,
    )
    .map((item) => item),
};

Polymer({
  is: 'order-list',

  _entity: 'order',

  behaviors: [global.App.ListViewBase],

  properties: {
    subtypeSetToolipInfos: {
      type: Array,
      value() {
        return global.App.CONST.ORDER_SUBTYPE.map((subtype) => global.App.LANG.CURRENT.LABEL.ORDER_SUBTYPE[subtype]);
      },
    },
    stateSetToolipInfos: {
      type: Array,
      value() {
        return global.App.CONST.ORDER_STATE.map((state) => global.App.LANG.CURRENT.LABEL.ORDER_STATE[state]);
      },
    },
    filterBy: {
      type: Object,
      notify: true,
      value: FILTER_ORDER_INITIAL,
    },
  },

  // observers: [
  //   '_loadPaginatedItems(filterBy.*, dateRange.*)',
  // ],

  _filterItems() {
    this._loadPaginatedItems(this.filterBy, this.dateRange);
  },

  attached() {
    this._loadPaginatedItems(this.filterBy, this.dateRange);

    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
      this._loadPaginatedItems(this.filterBy, response);
    });

    global.App.EVENT.on('action:refresh', () => {
      this._loadPaginatedItems();
    });
  },

  _paginate(response) {
    if (response.page) {
      const { page } = response;
      this.set('page', page);
    }

    if (response.pageSize) {
      const { pageSize } = response;
      this.set('pageSize', pageSize);
    }

    this._loadPaginatedItems(this.filterBy, this.dateRange);
  },

  _computedFetchPaginatedParams(filterBy, dateRange) {
    if (filterBy && filterBy.base) {
      // reset page to initiatl on-filterBy-changed: the new result should be initiated from page=1
      this.set('page', global.App.CONST.PAGINATE_INITIAL.page);
    }

    dateRange = Object.assign(this.dateRange, dateRange.base);
    filterBy = { ...(filterBy.base || filterBy) };

    if (filterBy.state) {
      filterBy.state = filterBy.state.map((item) => item.value);
    } else {
      delete filterBy.state;
    }

    if (filterBy.subtype) {
      filterBy.subtype = filterBy.subtype.map((item) => item.subtype);
    } else {
      delete filterBy.subtype;
    }

    if (filterBy.client) {
      filterBy.client = filterBy.client.id;
    } else {
      delete filterBy.client;
    }

    if (filterBy.driver) {
      filterBy.driver = filterBy.driver.id;
    } else {
      delete filterBy.driver;
    }

    const options = {
      paginate: {
        page: this.page,
        pageSize: this.pageSize,
      },
      filterBy,
    };

    if (dateRange && dateRange.from) {
      options.from = dateRange.from;
    } else {
      // delete options.from;
      const _from = global.App.moment().toDate();
      _from.setDate(1);
      options.from = _from.toISOString().split('T')[0];
    }

    if (dateRange && dateRange.until) {
      options.until = dateRange.until;
    } else {
      // delete options.until;
      const _until = global.App.moment()
        .endOf('month')
        .toDate();
      options.until = _until.toISOString().split('T')[0];
    }

    return options;
  },

  _loadPaginatedItems(filterBy, dateRange) {
    this.set('loaded', false);

    global.App.fetch('/admin/order', {
      query: this._computedFetchPaginatedParams(filterBy, dateRange),
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        this._loaded();

        if (!response.meta) {
          return;
        }

        const paginate = response.meta;
        this.set('page', paginate.page);
        this.set('count', paginate.count);
        this.set('pageSize', paginate.pageSize);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _fetchDriverOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/driver',
      item: 'driver',
      key: 'name',
    });
  },

  _fetchClientOptionsOnInput(event, detail) {
    this._fetchSelectOptionsOnInput(event, {
      path: '/admin/client',
      item: 'client',
      key: 'name',
    });
  },

  _inputSubtypeChanged(event, detail) {
    const input = (event.detail.value || '').trim().toLowerCase();

    if (input) {
      event.target.options = FILTER_ORDER_SET.subtype.filter((item) => item.label.toLowerCase().indexOf(input) === 0);
    } else {
      event.target.options = [];
    }
  },

  _inputStateChanged(event, detail) {
    const input = (event.detail.value || '').trim().toLowerCase();

    if (input) {
      event.target.options = FILTER_ORDER_SET.state.filter((item) => item.label.toLowerCase().indexOf(input) === 0);
    } else {
      event.target.options = [];
    }
  },

  _goDetailMap(event, detail) {
    this._goDetails(event, detail);

    const { item } = detail;

    global.App.EVENT.emit('map:route:draw', {
      requestAddress: item.requestAddress,
      destinationAddress: item.destinationAddress,
      route: item.route,
      startPosition: item.startPosition,
    });

    if (!item.startPosition) {
      return;
    }

    global.App.EVENT.emit('map:markers:draw', {
      markerSet: [
        {
          position: item.startPosition,
        },
      ],
    });
  },
});
