Polymer({
  is: 'client-form',

  _entity: 'client',

  behaviors: [global.App.ElementBase],

  properties: {
    itemValue: {
      type: String,
      notify: true,
    },
    item: {
      type: Object,
      notify: true,
      value() {
        return {
          user: {},
        };
      },
    },
  },

  observers: ['_computeValue(itemValue)'],

  _computeValue(itemValue) {
    const regExp = /^[0-9]+$/;
    const value = regExp.test(itemValue) ? 'item.user.phone' : 'item.user.name';
    if (regExp.test(itemValue)) {
      this.$.nameInput.focus();
    } else {
      this.$.phoneInput.focus();
    }
    this.set(value, itemValue);
  },

  do() {
    const body = this.$$('#form').serialize();
    global.App.fetch('/admin/client/create', {
      method: 'POST',
      body,
    })
      .then((response) => {
        this.set('item.user', {});
        this._fetchSuccessHandler(response);
        global.App.EVENT.emit('item:add', response[this._entity]);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
