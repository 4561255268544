
require('../iron-flex-layout/iron-flex-layout.html');

require('../iron-flex-layout/classes/iron-flex-layout.html');

require('./color.html');

require('./default-theme.html');

require('./shadow.html');

require('./typography.html');
