Polymer({
  is: 'order-business-list-view',

  _entity: 'order',

  behaviors: [global.App.ListViewBase, global.App.MapHelpers],

  properties: {
    selectedItem: {
      type: Object,
      notify: true,
    },
  },

  attached() {
    this._loadPaginatedItems();
  },

  _loadPaginatedItems() {
    this.set('loaded', false);

    global.App.fetch('/admin/order?filterBy[type]=custom')
      .then((response) => {
        this._fetchSuccessHandler(response);
        this._loaded();
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _showDetail(event, detail) {
    const { item } = detail;
    this.set('selectedItem', item);
  },

  _waypoints(addressSet) {
    if (!addressSet) {
      return undefined;
    }

    return addressSet.map((address) => ({
      location: `${String(address.position[0])},${String(address.position[1])}`,
    }));
  },

  _computedEndPosition(addressSet) {
    if (!addressSet) {
      return undefined;
    }
    const address = addressSet[addressSet.length - 1].position;
    return this._position(address);
  },
});
