global.App.ElementBase = global.App.utils.merge({}, global.App.TemplateHelpers, global.App.TemplateExtraHelpers, {
  _entity: 'entity',

  _dataField: 'item',

  _successMessage: 'Tâche effectuée!',

  _failureMessage: 'Echec de chargement!',

  properties: {
    $CONST: {
      type: Object,
      readonly: true,
      value() {
        return global.App.CONST;
      },
    },
    loaded: {
      type: Boolean,
      value: false,
    },
  },

  _loaded() {
    this.loaded = true;
  },

  _fetchSuccessHandler(response) {
    this[this._dataField] = response[this._entity];
    this.fire('success', this[this._dataField]);
    this.issues = {};
    this._loaded();
  },

  _fetchFailureHandler(error) {
    let message;
    if (error) {
      message = error.message;
      this.issues = error.issues;
    } else {
      message = this._failureMessage;
    }
    app.showToast(message);
    this.fire('failure', message);
    this._loaded();
  },

  _successHandler(event, detail) {
    this[this._dataField] = detail.response[this._entity];
    this.fire('success', this[this._dataField]);
    this.issues = {};
  },

  _failureHandler(event, detail) {
    this[this._dataField] = [];
    let message;
    const { response } = event.detail.request.xhr;
    if (response) {
      message = response.message;
      this.issues = response.issues;
    } else {
      message = this._failureMessage;
    }
    app.showToast(message);
    this.fire('failure', message);
  },

  _err(errors, alternative) {
    if (Array.isArray(errors)) {
      return errors[0].message;
      // return errors.map(function (v) {
      //   return v.message;
      // }).join(', ');
    }
    return alternative;
  },

  _invalid(errors) {
    return !!errors;
  },
});
