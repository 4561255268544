global.App.DetailViewBehavior = global.App.utils.merge({}, global.App.ElementBase, {
  properties: {
    item: {
      type: Object,
      notify: true,
    },
    label: {
      type: String,
      value: '',
    },
  },

  // [GET] ITEMS using FETCH_API
  _fetchItems(path, query) {
    if (!path) {
      return;
    }

    if (this.loaded) {
      this.loaded = false;
    }

    global.App.fetch(path, {
      query: query || '',
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _socketItemUpdated(item, properties) {
    if (Array.isArray(properties)) {
      properties.forEach(function (property) {
        this.set(`item.${property}`, item.value[property]);
      });
    } else {
      const property = properties;
      this.set(`item.${property}`, item.value[property]);
    }
  },

  _submit() {
    this.$.form.do();
  },

  _goEdit() {
    app.$.cache.set(this.item.id, this.item);
    app.$.state.goTo(`${this._entity}.edit`, this.item.id);
  },

  _closeSideView() {
    app.$.state.goTo(this._entity);
  },

  _updateItem(event, response) {
    if (!response) {
      response = event;
    }

    if (!response || response.id !== this.item.id) {
      return;
    }
    this.set('item', { ...this.item, ...response });
  },

  attached() {
    global.App.EVENT.on('item:update', (response) => {
      this._updateItem(response);
    });
  },
});
