Polymer({
  is: 'comment-input',

  properties: {
    label: {
      type: String,
      notify: true,
    },
    message: {
      type: String,
      notify: true,
    },
  },
});
