const socketio = require('socket.io-client');

function INIT_APP() {
  const app = document.querySelector('#app');

  Polymer.Base.mixin(app, global.App.ElementBase);

  app.name = 'E-Taxi Administration';

  function makeItemHandler(stateName, subState) {
    const param = `${stateName}Id`;
    if (!subState) {
      subState = 'detail';
    }
    return function (context) {
      let id;
      let data;
      if (subState !== 'add') {
        id = context.params[param];
        data = {};
        data[stateName] = app.$.cache.get(id) || {
          id,
        };
      }
      app.$.state.go(stateName, {
        context,
        sub: {
          name: `${stateName}.${subState}`,
          data,
        },
      });
    };
  }

  function makeHomeItemHandler(stateName, subState, subSubState) {
    const param = `${subState}Id`;
    if (!subSubState) {
      subSubState = 'detail';
    }
    return function (context) {
      debug('state home handler 1', stateName, subState, subSubState, param);
      let id;
      let data;
      if (subSubState !== 'add') {
        id = context.params[param];
        data = {};
        data[subState] = app.$.state.get(id) || {
          id,
        };
      }
      debug('state home handler 2', stateName, subState, subSubState, id, data);
      app.$.state.go(stateName, {
        context,
        sub: {
          name: `${stateName}.${subState}.${subSubState}`,
          data,
        },
      });
    };
  }

  // function authenticatedAccessMiddleware(context, next) {
  //   if (!app.isAuthenticated()) {
  //     this.goTo('login');
  //   }
  //   next();
  // }

  app.CONNECTED_STATE = {
    true: {
      label: 'Connecté',
      icon: 'device:signal-wifi-4-bar',
      class: '-text-positive',
    },
    false: {
      label: 'Non connecté',
      icon: 'device:signal-wifi-off',
      class: '-text-negative',
    },
  };

  app.STATES = {
    about: {
      url: '/about',
      title: 'Information',
      public: true,
    },
    login: {
      url: '/login',
      title: 'Connexion',
      public: true,
    },
    'account.verification': {
      url: '/account/verification/:token',
      title: 'Vérification de compte',
      public: true,
    },
    logout: {
      url: '/logout',
      handler() {
        app.logout();
      },
    },
    profile: {
      url: '/profile',
      title: 'Profil',
    },
    'profile.password_change': {
      url: '/profile/password_change',
      title: 'Réinitialisation de mot de passe',
      handler: makeItemHandler('profile', 'password_change'),
    },
    home: {
      url: '/',
      title: 'Tableau de Bord',
    },
    'home.order': {
      url: '/map/order',
      title: 'Passer une commande',
      handler: makeItemHandler('home', 'order'),
    },
    'home.sms': {
      url: '/map/sms',
      title: 'Envoi de SMS',
      handler: makeItemHandler('home', 'sms'),
    },
    'home.client.ask': {
      url: '/map/client/:clientId/order',
      title: 'Lancer une course',
      handler: makeHomeItemHandler('home', 'client', 'ask'),
    },
    'home.booking': {
      url: '/map/booking',
      title: 'Reserver une course',
      handler: makeItemHandler('home', 'booking'),
    },
    // business: {
    //   url: '/order-business',
    //   title: "Courses Entreprise",
    // },
    order: {
      url: '/order',
      title: 'Courses',
    },
    // order: {
    //   url: '/order?driver=:driverId',
    //   title: "Immédiates",
    //   handler() {
    //     let param = 'driverId';
    //     return function (context) {
    //       debug('order?driver', context.query.driverId);
    //     };
    //   },
    // },
    'order.detail': {
      url: '/order/:orderId',
      title: 'Course',
      handler: makeItemHandler('order'),
    },
    booking: {
      url: '/booking',
      title: 'Réservations',
    },
    'booking.detail': {
      url: '/booking/:bookingId',
      title: 'Réservation',
      handler: makeItemHandler('booking'),
    },
    taxi: {
      url: '/taxi',
      title: 'Taxis',
    },
    'taxi.add': {
      url: '/taxi/add',
      title: 'Taxi',
      handler: makeItemHandler('taxi', 'add'),
    },
    'taxi.edit': {
      url: '/taxi/:taxiId/edit',
      title: 'Taxi',
      handler: makeItemHandler('taxi', 'edit'),
    },
    'taxi.detail': {
      url: '/taxi/:taxiId',
      title: 'Taxi',
      handler: makeItemHandler('taxi'),
    },
    track: {
      url: '/track',
      title: 'Tracking de Taxi',
    },
    client: {
      url: '/client',
      title: 'Clients',
    },
    'client.add': {
      url: '/client/add',
      title: 'Ajouter client',
      handler: makeItemHandler('client', 'add'),
    },
    'client.edit': {
      url: '/client/:clientId/edit',
      title: 'Editer client',
      handler: makeItemHandler('client', 'edit'),
    },
    'client.detail': {
      url: '/client/:clientId',
      title: 'Client',
      handler: makeItemHandler('client'),
    },
    admin: {
      url: '/admin',
      title: 'Administrateurs / Opérateurs',
    },
    'admin.add': {
      url: '/admin/add',
      title: 'Admin',
      handler: makeItemHandler('admin', 'add'),
    },
    'admin.edit': {
      url: '/admin/:adminId/edit',
      title: 'Admin',
      handler: makeItemHandler('admin', 'edit'),
    },
    'admin.detail': {
      url: '/admin/:adminId',
      title: 'Admin',
      handler: makeItemHandler('admin'),
    },
    statistics: {
      url: '/statistics',
      title: 'Statistiques',
    },
    orderByClientReport: {
      url: '/report/client/order',
      title: 'Rapport de Courses par Client',
    },
    orderByTaxiReport: {
      url: '/report/taxi/order',
      title: 'Rapport de Courses par Taxi',
    },
    connectivityTaxiReport: {
      url: '/report/taxi/connectivity',
      title: 'Rapport de Connectivité des Taxis',
    },
  };

  app.goToState = function (event, detail) {
    event.stopImmediatePropagation();
    event.stopPropagation();
    event.preventDefault();
    const target = Polymer.dom(event).path.find((element) => element.dataset && element.dataset.state);
    const state = target ? target.dataset.state || 'none' : 'none';
    debug('goToState', target, state);
    app.$.state.goTo(state);
    // app.$.state.go(state);
    // if (state !== 'none') {
    //   history.pushState({}, null, '#!' + app.$.state.stateSet[state].url);
    // }
  };

  app.goLogin = function goLogin(event) {
    event.stopPropagation();
    app.$.state.goTo('login');
  };

  app.goProfile = function goProfile() {
    app.$.state.goTo('profile');
  };

  global.App.EVENT.on(':logout', () => {
    app.logout('Session expirée!');
  });

  Object.assign(app, global.App.Auth);

  app.init = function init() {
    app.authenticated = !!app.token;
    // debug('auth check', app.token, app.authenticated);
    if (app.isAuthenticated()) {
      app.setupConnection();
    }

    app.$.state.addEventListener('state-changing', (event) => {
      event.stopImmediatePropagation();
      const state = event.detail;

      debug('state-changing', state);

      if (state.last && state.name === state.last.name) {
        app.$.mainSelector.selected = 'none';
        app.$.sideViewSelector.selected = 'none';
      } else {
        app.scrollMainViewToTop();
      }
      if (!state.sub || (state.last && state.last.sub && state.sub.name === state.last.sub.name)) {
        app.$.sideViewSelector.selected = 'none';
      } else {
        app.scrollSubViewToTop();
      }
    });

    app.$.state.addEventListener('state-change', (event) => {
      const state = event.detail;
      debug('state-changed', app.state);

      app.closeMenu();
      if (state.sub) {
        app.openSideView();
      } else {
        app.closeSideView();
      }
      document.title = `${app.name} | ${state.title}`;
    });

    this.$.state.setup();
  };

  app.setupConnection = function setupConnection() {
    global.App.socketio = socketio.connect(`${global.App.CONFIG.ENDPOINT}/_`, {
      transports: ['websocket'],
      upgrade: false,
      query: {
        token: app.token,
      },
    });

    global.App.socketio.on('connect', () => {
      app.showToast('Connecté!');
    });

    global.App.socketio.on('disconnect', function () {
      debug('disconnect', arguments);
      app.showToast('Déconnecté!');
    });

    global.App.socketio.on('connect_error', function (error) {
      debug('connect_error', arguments);
    });

    global.App.socketio.on('error', (error) => {
      debug('error', error);
      if (error === 'unauthenticated') {
        global.App.EVENT.emit(':logout');
      } else if (error === 'logout') {
        global.App.EVENT.emit(':logout');
      } else {
        app.showToast('Echec de connection!');
      }
    });

    global.App.socketio.on('unauthenticated', function () {
      debug('unauthenticated', arguments);
      global.App.EVENT.emit(':logout');
    });

    global.App.socketio.on(':logout', function () {
      debug(':logout', arguments);
      global.App.EVENT.emit(':logout');
    });
  };

  window.addEventListener(
    'WebComponentsReady',
    function () {
      debug('WebComponentsReady');
      this.init();
    }.bind(app),
  );

  app.addEventListener('dom-change', () => {
    debug('app', 'dom-change');

    if (global.App.CONFIG.name !== 'development') {
      return;
    }

    // log all unregistered custom elements
    Array.from(document.querySelectorAll('html /deep/ *'))
      .filter((el) => (el.localName.indexOf('-') !== -1 || el.getAttribute('is')) && el.constructor === HTMLElement)
      .forEach((el) => console.warn('Found unregistered custom element:', el));
  });

  Object.assign(app, global.App.UI);

  app._showTitle = function (mobile, state) {
    const IGNORED_STATE = ['home', 'track'];
    return state && IGNORED_STATE.indexOf(state.name) === -1;
  };

  app._showHome = function (mobile, state) {
    return state && state.name === 'home';
  };
}

INIT_APP();
