global.App.ListViewBase = global.App.utils.merge({}, global.App.ElementBase, global.App.PaginateBehavior, {
  _dataField: 'items',

  properties: {
    pageSize: {
      type: Number,
      notify: true,
      value: global.App.CONST.PAGINATE_INITIAL.pageSize,
    },
    count: {
      type: Number,
      notify: true,
      value: global.App.CONST.PAGINATE_INITIAL.count,
    },
    page: {
      type: Number,
      notify: true,
      value: global.App.CONST.PAGINATE_INITIAL.page,
    },
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
    items: {
      type: Array,
      notify: true,
      value() {
        return [];
      },
    },
    selectedItem: {
      type: Object,
      notify: true,
    },
    selectedKey: {
      type: Object,
      notify: true,
    },
    verifiedClasses: {
      type: Object,
      readOnly: true,
      value: global.App.SHARED.VERIFIED_CLASS_SET,
    },
    connectedClasses: {
      type: Object,
      readOnly: true,
      value: global.App.SHARED.CONNECTED_CLASS_SET,
    },
    TaxiSubscriptionTypeClasses: {
      type: Object,
      readOnly: true,
      value: global.App.SHARED.TAXI_SUBSCRIPTION_TYPE_CLASS_SET,
    },
  },

  attached() {
    this.selectedItem = app.$.state.params[`${this._entity}Id`]; // @TODO selected item on refresh!
  },

  _search(path, query) {
    if (!path) {
      return;
    }

    this._fetchPaginatedItems(path, {
      paginate: this.paginate,
      q: query,
    });
  },

  // [GET] ITEMS using FETCH_API
  _fetchItems(path, query) {
    if (!path) {
      return;
    }
    global.App.fetch(path, {
      query: query || '',
    })
      .then((response) => {
        this._loaded();
        this._fetchSuccessHandler(response);
      })
      .catch((error) => {
        this._loaded();
        this._fetchFailureHandler(error);
      });
  },

  // [GET] ITEMS using SOCKET

  _getItems(path, options, callback) {
    if (!path) {
      return;
    }

    if (typeof options === 'function') {
      callback = options;
      options = {};
    }

    if (!options) {
      options = {};
    }

    if (this.loaded) {
      this.set('loaded', false);
    }

    const _entity = options.entity || this._entity;
    const _query = options.query || '';

    global.App.socketio.emit(path, _query, (error, result) => {
      this.set('loaded', true);
      if (error) {
        if (callback) {
          callback(error);
        } else {
          app.showToast(error.message);
        }
        return;
      }

      if (callback) {
        callback(null, result[_entity]);
      } else {
        const _item = Array.isArray(result[_entity]) ? 'items' : 'item';
        this.set(_item, result[_entity]);
      }
    });
  },

  _socketItemUpdated(item, keys) {
    if (!item) {
      return;
    }

    const index = this.items.findIndex((o) => o.id === item.id);

    if (index > -1) {
      if (!keys) {
        keys = Object.keys(item.value);
      }
      if (!Array.isArray(keys)) {
        keys = [keys];
      }

      keys.forEach((key) => {
        const value = item.value[key];
        this.set(`items.${index}.${key}`, value);
      });
    }
  },

  _goAdd() {
    app.$.state.goTo(`${this._entity}.add`);
  },

  _goDetails(event, detail) {
    const { item } = detail;
    app.$.cache.set(item.id, item);
    app.$.state.goTo(`${this._entity}.detail`, item.id);
  },

  _getTimedoutIndex(items, item) {
    if (!items || !item) {
      return -1;
    }

    const index = items.findIndex((o) => o.id === item.reinitiatedFor || o.reinitiatedFor === item.reinitiatedFor);

    return index;
  },

  _showDetailMap(event, detail) {
    const { item } = detail;
    global.App.EVENT.emit('map:order:itinerary', {
      requestPosition: item.requestAddress.position,
      destinationPosition: item.destinationAddress.position,
      startPosition: item.startPosition,
      route: item.route,
      taxi: item.taxi,
    });
  },
});
