Polymer({
  is: 'profile-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
    connectedClasses: {
      type: Object,
      readOnly: true,
      value: global.App.SHARED.CONNECTED_CLASS_SET,
    },
  },
});
