Polymer({
  is: 'admin-add-view',

  _entity: 'admin',

  behaviors: [global.App.ElementBase],

  _doSubmit() {
    this.$$('#form').do();
  },

  _closeSideView() {
    app.$.state.goTo(this._entity);
  },
});
