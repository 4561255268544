Polymer({
  is: 'taxi-detail-view',

  _entity: 'taxi',

  behaviors: [global.App.DetailViewBehavior],

  properties: {
    selectedState: {
      type: String,
      value: 'balance',
    },
  },

  attached() {
    this._fetchItems(`/admin/taxi/${this.item.id}`);
  },

  _doAddTag() {
    this.$$('#form').do();
  },

  _doEnableTaxi() {
    this._send('/admin/taxi/:taxiId/enable');
  },

  _doDisableTaxi() {
    this._send('/admin/taxi/:taxiId/disable');
  },

  _doSubscribeAsPremium() {
    this._send('/admin/taxi/:taxiId/subscription', {
      subscription: global.App.CONST.TAXI_SUBSCRIPTION_TYPE.PREMIUM,
    });
  },

  _doSubscribeAsFreemium() {
    this._send('/admin/taxi/:taxiId/subscription', {
      subscription: global.App.CONST.TAXI_SUBSCRIPTION_TYPE.FREEMIUM,
    });
  },

  _doResetPasswordTaxi() {
    this._send('/admin/taxi/:taxiId/reset-password');
  },

  _send(path, payload) {
    if (!path) {
      return;
    }
    payload = payload || {};
    global.App.socketio.emit(
      path,
      {
        taxiId: this.item.id,
        ...payload,
      },
      (error, result) => {
        if (error) {
          app.showToast(error.message);
          return;
        }
        app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
        global.App.EVENT.emit('item:update', result.taxi);
      },
    );
  },
});
