Polymer({
  is: 'set-tags-form',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: String,
      notify: true,
    },
    entity: {
      type: String,
      notify: true,
    },
    tags: {
      type: Object,
      value: [],
    },
  },

  do() {
    const body = this.tags && this.tags.length
      ? this.$$('#form').serialize()
      : {
        tags: [],
      };

    global.App.fetch(`/admin/${this.entity}/${this.item}/tags`, {
      method: 'POST',
      body,
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        this.fire('form-success', response[this.entity]);
        app.showToast(global.App.LANG.CURRENT.LABEL.SUCCESS);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
