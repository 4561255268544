const DAYS = {
  monday: 'Lundi',
  tuesday: 'Mardi',
  wednesday: 'Mercredi',
  thursday: 'Jeudi',
  friday: 'Vendredi',
  saturday: 'Samedi',
  sunday: 'Dimanche',
};

function _initialize(text) {
  return text.charAt(0).toUpperCase();
}

const TemplateHelpers = (global.App.TemplateHelpers = {});

Object.assign(TemplateHelpers, global.App.HELPER);

Object.assign(TemplateHelpers, {
  // Development utils

  _json(data) {
    return JSON.stringify(data, null, 2);
  },

  // TYPES

  _boolean(value) {
    return !!value;
  },

  _true() {
    return true;
  },

  _false() {
    return false;
  },

  _number(value) {
    return Number(value).toLocaleString('fr');
  },

  _integer(value) {
    return Math.floor(Number(value)).toLocaleString('fr');
  },

  _is(property, value) {
    if (!property) {
      return false;
    }
    return property === value;
  },

  _isNotIn(property, value) {
    return property !== value;
  },

  _in(property) {
    const values = Array.prototype.slice.call(arguments, 1);
    return values.indexOf(property) !== -1;
  },

  _if(condition, trueValue, falseValue) {
    return condition ? trueValue : falseValue;
  },

  _ifNot(condition, trueValue, falseValue) {
    return condition ? falseValue : trueValue;
  },

  _v(value, alternative) {
    return value || alternative;
  },

  _w(value /* , watch ... */) {
    return value;
  },

  // Date utils

  _isodate(value) {
    return value.toISOString();
  },

  _day(value) {
    return DAYS[String(value).toLowerCase()] || '?';
  },

  _duration(value, format) {
    const duration = global.App.moment.duration(value, 'seconds');
    return global.App.moment.duration(duration.as('minutes'), 'minutes').humanize();
  },

  // String util

  _concat() {
    return Array.prototype.join.call(arguments, '');
  },

  // Object utils

  _key(object, key, defaultValue) {
    return object[key || 0] || defaultValue;
  },

  _keyChange(changeRecord, key, defaultValue) {
    return changeRecord.base[key || 0] || defaultValue;
  },

  // Array utils

  _index(index) {
    return index + 1;
  },

  _isEmpty(data, length) {
    const r = typeof data === 'object' && !!data && data.length === 0;
    return r;
  },

  _isNotEmpty(data, length) {
    const r = typeof data === 'object' && !!data && data.length > 0;
    return r;
  },

  _countItemsOf(array, key, value) {
    return array.filter((v) => v[key] === value).length;
  },

  _filter(array, key, value) {
    return array.filter((v) => v[key] === value);
  }, // items="{{_filter(list,'name','value')}}"

  _filterBy(key, value, invert) {
    return function (item) {
      if (!value) {
        return true;
      }
      if (!item) {
        return false;
      }
      return invert ? item[key] !== value : item[key] === value;
    };
  }, // {{_filterBy('name','value')}}

  _filterByLike(key, value) {
    return function (item) {
      if (!value) {
        return true;
      }
      if (!item) {
        return false;
      }
      return item[key] && item[key].indexOf(value) !== -1;
    };
  }, // {{_filterByLike('name','value')}}

  _sort(array, key, reverse) {
    reverse = reverse ? -1 : 1;
    return array.sort((a, b) => reverse * (a[key] < b[key] ? -1 : 1));
  }, // items="{{_sort(list,'name')}}"

  _sortNumeric(array, key, reverse) {
    reverse = reverse ? -1 : 1;
    return array.sort((a, b) => reverse * (a[key] < b[key] ? -1 : 1));
  }, // items="{{_sortNumeric(list,'name')}}"

  _sortByKey(key, type = 'string') {
    let reverse = 1;
    if (key.charAt(0) === '+') {
      key = key.substr(1);
    } else if (key.charAt(0) === '-') {
      key = key.substr(1);
      reverse = -1;
    }
    if (key.indexOf(':') !== -1) {
      const _key = key.split(':');
      key = _key[0];
      type = _key[1];
    }
    // debug('_sortByKey', key, type);

    if (type === 'number') {
      return function (a, b) {
        return reverse * (a[key] - b[key]);
      };
    }
    if (type === 'boolean') {
      return function (a, b) {
        // eslint-disable-next-line no-nested-ternary
        return reverse * (a[key] === b[key] ? 0 : a ? 1 : -1);
      };
    }
    if (type === 'date') {
      return function (a, b) {
        return reverse * (new Date(a[key]).getTime() - new Date(b[key]).getTime());
      };
    } // string
    return function (a, b) {
      // debug('_sortByKey', key, type, a, b);
      // debug('_sortByKey', key, type, a[key], b[key]);
      // eslint-disable-next-line no-nested-ternary
      return reverse * (a[key] === b[key] ? 0 : a[key] < b[key] ? -1 : 1);
    };
  },

  _sortByMultipleKeys(keys) {
    keys = keys.split('|');

    const { _sortByKey } = TemplateHelpers;

    return function (a, b) {
      const _keys = keys.slice();
      let result = 0;
      // try {
      let _key;
      // eslint-disable-next-line no-cond-assign
      while (result === 0 && (_key = _keys.shift())) {
        result = _sortByKey(_key)(a, b);
      }
      // } catch (e) {
      //   debug('_sortByMultipleKeys', _keys, e);
      // }
      return result;
    };
  },

  _sortBy(path) {
    // debug('_sortBy', arguments);

    const { _sortByKey } = TemplateHelpers;
    const { _sortByMultipleKeys } = TemplateHelpers;

    if (path.indexOf('.') === -1) {
      if (path.indexOf('|') === -1) {
        return _sortByKey(path);
      }
      return _sortByMultipleKeys(path);
    }
    const keys = path.split('.');
    return function (a, b) {
      // debug('_sortBy', path);

      const _keys = keys.slice();
      const key = _keys.pop();
      let result = 0;
      // try {
      let _key;
      // eslint-disable-next-line no-cond-assign
      while ((_key = _keys.shift())) {
        a = a ? a[_key] : null;
        b = b ? b[_key] : null;
      }
      if (key.indexOf('|') === -1) {
        result = _sortByKey(key)(a, b);
      } else {
        result = _sortByMultipleKeys(key)(a, b);
      }
      // } catch (e) {
      //   debug('_sortBy', _keys, key, e);
      // }
      // debug('_sortBy', _keys, key, a[key], b[key], result);
      return result;
    };
  }, // {{_sortBy('name')}}

  _sortByTaxiState(a, b) {
    const TAXI_STATE = ['off', 'idle', 'busy', 'order'];
    // eslint-disable-next-line no-nested-ternary
    return a === b ? 0 : TAXI_STATE.indexOf(a) < TAXI_STATE.indexOf(b) ? -1 : 1;
  },

  // URLs

  _url(url, param) {
    return url.replace(':param', param);
  },

  _image(url) {
    if (url.charAt(0) !== '/') {
      url = `/unsafe${url}`;
    }
    return global.App.CONFIG.MEDIA_ENDPOINT + url;
  },

  _api(path, params, query) {
    let url = global.App.CONFIG.ENDPOINT + path;
    if (params) {
      if (typeof params !== 'object') {
        const p = {};
        p[this._entity !== 'entity' ? `${this._entity}Id` : 'param'] = params;
        params = p;
      }
      Object.keys(params).forEach((param) => {
        url = url.replace(`:${param}`, encodeURIComponent(params[param]));
      });
    }
    if (query) {
      url += `?${Object.keys(query)
        .map((param) => `${param}=${encodeURIComponent(query[param])}`)
        .join('&')}`;
    }
    return url;
  },

  // Style utils

  _class(base, condition, trueValue, falseValue) {
    return `${base} ${condition ? trueValue || '' : falseValue || ''}`;
  },

  // ?

  _resetInput(inputId) {
    if (!inputId) {
      return;
    }
    const input = document.getElementById(String(inputId));
    if (!input.value) {
      return;
    }
    input.value = null;
  },

  _computedClass(states, state) {
    const value = states[state];
    return value ? value.class || '' : '';
  },

  _computedLabel(states, state) {
    const value = states[state];
    return value && value.label ? value.label : '';
  },

  _computedIcon(states, state) {
    const value = states[state];
    return value ? value.icon || '' : '';
  },

  _computedRouteDuration(route, startedAt) {
    return global.App.HELPER.$estimateFromRoute(route, startedAt).durationFullText;
  },

  _computedRouteDistance(route, startedAt) {
    return global.App.HELPER.$estimateFromRoute(route, startedAt).distanceFullText;
  },

  _computedRouteCost(route, startedAt) {
    return global.App.HELPER.$estimateFromRoute(route, startedAt).costFullText;
  },

  goToState(event, detail) {
    app.goToState(event, detail);
  },

  _getIndex(items, item) {
    if (!items || !item) {
      return -1;
    }

    const index = items.findIndex((o) => o.id === item.id);

    return index;
  },

  _fetchSelectOptionsOnInput(event, options) {
    const input = (event.detail.value || '').trim().toLowerCase();

    if (input.length < 3) {
      return;
    }

    const _path = options.path;
    const _item = options.item || this._item;
    const _key = options.key || undefined;

    const _q = options.q || 'q';

    const _query = {};
    _query[_q] = input;

    if (input && _q) {
      global.App.socketio.emit(_path, _query, (error, result) => {
        if (error) {
          event.target.options = [];
          return;
        }

        event.target.options = result[_item].map((item) => {
          if (_key && !item[_key]) {
            item[_key] = item.user[_key];
          }
          return item;
        });
      });
    } else {
      event.target.options = [];
    }
  },

  _initials(name) {
    if (!name) {
      return '';
    }
    return name
      ? name
        .split(' ')
        .map(_initialize)
        .join('')
      : '?';
  },
});

global.App.TemplateHelpers._oneOf = global.App.TemplateHelpers._in;
