Polymer({
  is: 'order-immediate-cancel',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
      value() {
        return {
          cancelReason: '',
          cancelNote: '',
        };
      },
    },
    cancelReasonSet: {
      type: Array,
      readOnly: true,
      value: global.App.SHARED.ORDER_CANCEL_REASON_SET,
    },
    cancelReason: {
      type: Object,
      value: global.App.SHARED.ORDER_CANCEL_REASON_SET[0],
    },
  },

  _setItem() {
    this.set('item.cancelReason', this.cancelReason.value);
    this.notifyPath('item.cancelReason');
  },
});
