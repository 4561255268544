
require('./auth/about-view.html');

require('./auth/auth-view.html');

require('./auth/account-verification-view.html');

require('./auth/profile-settings-view.html');

require('./home/map-view.html');

require('./taxi/map-taxi-list-view.html');

require('./taxi/taxi-item.html');

require('./taxi/taxi-list-view.html');

require('./taxi/taxi-detail-view.html');

require('./taxi/taxi-add-view.html');

require('./taxi/taxi-tracking-view.html');

require('./client/client-list-view.html');

require('./client/client-detail-view.html');

require('./client/client-add-view.html');

require('./client/client-edit-view.html');

require('./order/map-order-request-view.html');

require('./order/order-list-view.html');

require('./order/order-detail-view.html');

require('./order/map-order-followup-view.html');

require('./order/map-order-timedout-view.html');

require('./order/order-business-list-view.html');

require('./order/order-state-utils.html');

require('./booking/map-booking-followup-view.html');

require('./admin/admin-list-view.html');

require('./admin/admin-detail-view.html');

require('./admin/admin-add-view.html');

require('./shared/statistics-overview.html');

require('./shared/booking-reminder.html');

require('./utilities/map-sms-view.html');

require('./analytics/statistics-view.html');

require('./analytics/order-by-client-report-view.html');

require('./analytics/order-by-taxi-report-view.html');

require('./analytics/connectivity-taxi-report-view.html');
