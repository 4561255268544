Polymer({
  is: 'order-item',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      notify: true,
    },
  },

  ready() {
    global.App.socketio.on('admin:order:state', (payload) => {
      if (this.item && payload.order.id !== this.item.id) {
        return;
      }
      this.set('item', payload.order);
    });
  },
});
