Polymer({
  is: 'taxi-balance',

  behaviors: [global.App.ListViewBase],

  properties: {
    taxi: {
      type: String,
      notify: true,
    },
    timestamp: {
      type: String,
      notify: true,
      value() {
        return global.App.moment()
          .startOf('hour')
          .add(1, 'hour')
          .format('YYYY-MM-DDTHH:mm');
      },
    },
    balance: {
      type: Object,
      notify: true,
    },
    dateRange: {
      type: Object,
      notify: true,
      value: {},
    },
  },

  observers: ['_loadPaginatedItems(taxi,dateRange.*)'],

  attached() {
    global.App.EVENT.on('transaction:add', (response) => {
      if (!response) {
        return;
      }
      this.unshift('items', response);
    });

    global.App.EVENT.on('date:change', (response) => {
      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });

    global.App.EVENT.on('action:refresh', () => {
      this._loadPaginatedItems();
    });

    global.App.EVENT.on('item:update', (response) => {
      if (!response || response.id !== this.balance.id) {
        return;
      }
      this.set('balance', { ...this.balance, ...response });
    });
  },

  _paginate(response) {
    if (response.page) {
      const { page } = response;
      this.set('page', page);
    }

    if (response.pageSize) {
      const { pageSize } = response;
      this.set('pageSize', pageSize);
    }

    this._loadPaginatedItems();
  },

  _computedFetchPaginatedParams() {
    const options = {
      paginate: {
        page: this.page,
        pageSize: this.pageSize,
      },
    };

    if (this.query) {
      options.q = this.query;
    } else {
      delete options.q;
    }
    return options;
  },

  _computedValueClass(value) {
    if (!value) {
      return '';
    }
    return value < 0 ? '-text-negative' : '-text-positive';
  },

  _loadPaginatedItems(taxi, dateRange) {
    taxi = taxi || this.taxi;
    this.set('loaded', false);

    global.App.fetch(`/admin/taxi/${taxi}/balance`, {
      query: this._computedFetchPaginatedParams(),
    })
      .then((response) => {
        this.set('items', response.transaction);
        this.set('balance', response.balance);
        this._loaded();

        if (!response.meta) {
          return;
        }

        const paginate = response.meta;
        this.set('page', paginate.page);
        this.set('count', paginate.count);
        this.set('pageSize', paginate.pageSize);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _doAddTransaction() {
    this.$$('#form').do();
  },
});
