const DELAY = 30 * 24 * 3600 * 1000; // a month-ago

Polymer({
  is: 'date-range-selection',

  properties: {
    _from: {
      type: Date,
      notify: true,
      value() {
        const _from = global.App.moment().toDate();
        _from.setDate(1);
        return _from.toISOString().split('T')[0];
      },
    },
    _until: {
      type: Date,
      notify: true,
      value() {
        const _until = global.App.moment()
          .endOf('month')
          .toDate();
        return _until.toISOString().split('T')[0];
      },
    },
  },

  observers: ['_dateChange(_from,_until)'],

  _dateUntilLoadEmpty() {
    const now = new Date();
    this._until = this._until || now.toISOString().split('T')[0];
  },

  _dateFromLoadEmpty() {
    const now = new Date();
    const _from = new Date(now.setDate(1));
    this._from = this._from || _from.toISOString().split('T')[0];
  },

  _validateDate(_from, _until) {
    if (_from > _until) {
      app.showToast('date invalide.');
      _from = new Date(new Date(_until).getTime() - DELAY);
      _from = _from.toISOString().split('T')[0];
      this.set('_from', _from);
    }
    return _from;
  },

  _dateChange(_from, _until) {
    if (!_from || !_until) {
      return;
    }

    this.debounce(
      'dateChange',
      function () {
        _from = this._validateDate(_from, _until);
        global.App.EVENT.emit('date:change', {
          from: this._from,
          until: this._until,
        });
      },
      1800,
    );
  },

  attached() {
    this._dateChange(this._from, this._until);
  },
});
