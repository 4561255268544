Polymer({
  is: 'taxi-form',

  _entity: 'taxi',

  behaviors: [global.App.ElementBase],

  properties: {
    item: {
      type: Object,
      value() {
        return {
          user: {},
          driver: {},
          taxi: {},
        };
      },
    },
  },

  do() {
    const body = this.$$('#form').serialize();
    global.App.fetch('/admin/taxi/create', {
      method: 'POST',
      body,
    })
      .then((response) => {
        this._fetchSuccessHandler(response);
        global.App.EVENT.emit('item:add', response[this._entity]);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },
});
