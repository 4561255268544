Polymer({
  is: 'statistics-view',

  behaviors: [global.App.ElementBase],

  properties: {
    params: {
      type: Object,
      notify: true,
    },
    clientCumulated: {
      type: Object,
      notify: true,
      value: [],
    },
    client: {
      type: Object,
      notify: true,
    },
    clientStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Comptes Clients',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          colors: ['#3366cc', '#990099', '#FF0080', '#808000', '#8BC34A'],
        };
      },
    },
    account: {
      type: Object,
      notify: true,
    },
    clientAccountStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Comptes clients créés par opérateurs',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          colors: ['#3366cc', '#808000', '#8BC34A', 'red'],
        };
      },
    },
    call: {
      type: Object,
      notify: true,
    },
    callStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Appels Téléphoniques',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          colors: ['#3366cc'],
        };
      },
    },
    taxi: {
      type: Object,
      notify: true,
    },
    taxiStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Comptes Taxis',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          colors: ['#3366cc', 'green', 'orange'],
        };
      },
    },
    order: {
      type: Object,
      notify: true,
    },
    orderStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Immédiates',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          colors: ['#3366cc', '#990099', 'green', 'red', 'orange'],
        };
      },
    },
    booking: {
      type: Object,
      notify: true,
    },
    bookingStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Réservations',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          colors: ['#3366cc', '#990099', 'green', 'red', 'orange'],
        };
      },
    },
    orderMetrics: {
      type: Object,
      notify: true,
    },
    distanceStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Distance des courses, en Km',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          histogram: {
            // bucketSize: 2,
            hideBucketItems: true,
            minValue: 0,
          },
          isStacked: true,
          colors: ['#3366cc'],
        };
      },
    },
    timeStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Horaire des courses',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          histogram: {
            bucketSize: 2,
            hideBucketItems: true,
            minValue: 0,
            maxValue: 24,
          },
          interpolateNulls: false,
          colors: ['#3366cc', 'green', 'red'],
        };
      },
    },
    taxiClientDistanceStatisticsDiagramOptions: {
      type: Object,
      readOnly: true,
      value() {
        return {
          title: 'Distance entre clients et taxis',
          lineWidth: 1,
          vAxis: {
            minValue: 0,
            maxValue: 1,
          },
          backgroundColor: '#EEEEEE',
          legend: {
            alignment: 'start',
            position: 'bottom',
          },
          toolip: {
            textStyle: {
              color: '#FF0000',
            },
            showColorCode: true,
          },
          histogram: {
            bucketSize: 2,
            hideBucketItems: true,
            minValue: 0,
          },
          interpolateNulls: false,
          colors: ['#3366cc', 'green', 'red'],
        };
      },
    },
  },

  observers: ['_loadFetchedItems(dateRange.*)'],

  ready() {
    global.App.EVENT.on('date:change', (response) => {
      debug('date:change:statistics-view', response);

      if (!response) {
        return;
      }
      this.set('dateRange', response);
    });
  },

  attached() {
    const date = {
      from: JSON.parse(window.localStorage.getItem('etaxi.dateFrom')),
      until: JSON.parse(window.localStorage.getItem('etaxi.dateUntil')),
    };
    debug('date', date);
    this.set('dateRange', date);
    this._loadFetchedItems(this.dateRange);
  },

  _loadFetchedItems(dateRange) {
    this._getFetchedClientStatistics(this.dateRange.from, this.dateRange.until);
    this._getFetchedClientAccountStatistics(this.dateRange.from, this.dateRange.until);
    this._getFetchedCallStatistics(this.dateRange.from, this.dateRange.until);
    this._getFetchedTaxiStatistics(this.dateRange.from, this.dateRange.until);
    this._getFetchedOrderStatistics(this.dateRange.from, this.dateRange.until);
    this._getFetchedBookingStatistics(this.dateRange.from, this.dateRange.until);
    this._getFetchedDistanceStatistics(this.dateRange.from, this.dateRange.until);
  },

  _calculateClientCumulated(response) {
    if (response && response.count !== 0) {
      const data = [];
      let { total } = response.count;
      let { autoCreated } = response.count;
      let opCreated = response.count.total - response.count.autoCreated;
      let { phoneVerified } = response.count;
      let { emailVerified } = response.count;

      // data = data.map(function(record) {
      //   total += record[1];
      //   return [record[0], total];
      // });

      response.data.reverse().forEach((record) => {
        total -= record[1];
        autoCreated -= record[2];
        opCreated -= record[3];
        phoneVerified -= record[4];
        emailVerified -= record[5];
        data.unshift([record[0], total, autoCreated, opCreated, phoneVerified, emailVerified]);
      });

      this.set('clientCumulated', data);
    }
  },

  _validateDate(_from, _until) {
    if (_from >= _until) {
      this.$.toast.open();
      const delay = 24 * 3 * 3600 * 1000;
      _from = new Date(new Date(_until).getTime() - delay);
      _from = _from.toISOString().split('T')[0];
      this.set('_from', _from);
    }
    return _from;
  },

  _selectedField(fieldSet, start, end) {
    const _fieldSet = fieldSet.slice(start, end);
    return _fieldSet;
  },

  _selectedData(dataSet, start, end) {
    const _dataSet = [];
    dataSet.forEach((data) => {
      _dataSet.push(data.slice(start, end));
    });
    return _dataSet;
  },

  _getFetchedClientStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/client-per-day', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this._successStatisticsHandler(response, 'client');
        this._calculateClientCumulated(response);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _getFetchedClientAccountStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/client-account-per-day', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this._successStatisticsHandler(response, 'account');
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _getFetchedCallStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/call-per-day', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this._successStatisticsHandler(response, 'call');
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _getFetchedTaxiStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/taxi-per-day', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this._successStatisticsHandler(response, 'taxi');
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _getFetchedOrderStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/order-immediate-per-day', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this._successStatisticsHandler(response, 'order');
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _getFetchedBookingStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/order-booking-per-day', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this._successStatisticsHandler(response, 'booking');
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _getFetchedDistanceStatistics(_from, _until) {
    global.App.fetch('/admin/statistics/order-metrics-per-date', {
      query: {
        from: _from,
        until: _until,
      },
    })
      .then((response) => {
        this.set('orderMetrics', response);
      })
      .catch((error) => {
        this._fetchFailureHandler(error);
      });
  },

  _successStatisticsHandler(items, property) {
    items.data = items.data.map((item) => {
      item = item.slice();
      item[0] = new Date(item[0]);
      return item;
    });
    this.set(property, items);
  },
});
