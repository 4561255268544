const FILTER_TAXI_SET = {
  state: [
    {
      value: global.App.CONST.TAXI_STATE.IDLE,
      label: global.App.LANG.CURRENT.LABEL.TAXI_STATE[global.App.CONST.TAXI_STATE.IDLE],
      checked: true,
    },
    {
      value: global.App.CONST.TAXI_STATE.BUSY,
      label: global.App.LANG.CURRENT.LABEL.TAXI_STATE[global.App.CONST.TAXI_STATE.BUSY],
      checked: true,
    },
    {
      value: global.App.CONST.TAXI_STATE.ORDER,
      label: global.App.LANG.CURRENT.LABEL.TAXI_STATE[global.App.CONST.TAXI_STATE.ORDER],
      checked: true,
    },
    {
      value: 'off-active',
      label: 'OFF actif',
      checked: true,
    },
    {
      value: 'off-recent',
      label: 'OFF récemment actif',
      checked: false,
    },
    {
      value: global.App.LANG.CURRENT.LABEL.TAXI_STATE[global.App.CONST.TAXI_STATE.OFF],
      label: 'Tous les OFF',
      checked: false,
    },
  ],
};

const FILTER_TAXI_INITIAL = {
  state: FILTER_TAXI_SET.state.filter((item) => item.checked).map((item) => item.value),
};

Polymer(
  global.App.utils.merge({}, global.App.ListViewBase, {
    is: 'map-taxi-list-view',

    _entity: 'home',

    behaviors: [global.App.ListViewBase],

    properties: {
      filterSet: {
        type: Array,
        value: FILTER_TAXI_SET,
      },
      filterValue: {
        type: Object,
        value: FILTER_TAXI_INITIAL,
      },
    },

    observers: ['_filterByState(filterValue.state.*)'],

    attached() {
      this.timer = setInterval(() => {
        this._fetchItems({
          state: this.filterValue.state,
        });
      }, 30000);
    },

    detached() {
      clearInterval(this.timer);
    },

    _filterByState(filterBy) {
      if (!this.$.mapBounds.value) {
        return;
      }
      this._fetchItems({
        state: filterBy.base || filterBy || this.filterValue,
      });
    },

    _fetchItems(fetchOptions) {
      const options = {
        entity: 'taxi',
      };

      options.query = {};

      if (fetchOptions.q) {
        options.query.q = fetchOptions.q;
      }

      if (fetchOptions.filterBy) {
        options.query = {
          filterBy: fetchOptions.filterBy,
        };
      }

      if (fetchOptions.state) {
        options.query.state = fetchOptions.state;
      }

      options.query.bounds = fetchOptions.bounds || this.$.mapBounds.value;

      const path = fetchOptions.path || '/admin/taxi/bounds';
      this._getItems(path, options, (error, taxiSet) => {
        taxiSet = taxiSet.sort(this._sortBy('state|registration'));

        this.set('items', taxiSet);
        global.App.EVENT.emit('map:taxi:refresh', taxiSet);
      });
    },

    _loadTaxi(event, detail) {
      // fired on-bounds-changed
      if (!detail || !detail.item.bounds) {
        return;
      }

      const { bounds } = detail.item;

      this._fetchItems({
        state: this.filterValue.state,
        bounds,
      });
    },

    _search(event, detail) {
      this._fetchItems({
        path: '/admin/taxi/by/driver',
        q: this.search || detail.item,
      });
    },

    _selectItemOnTab(event, detail) {
      this.$.datatableCard.select(detail.item);
    },

    _goDetails(event, detail) {
      const { item } = detail;
      global.App.EVENT.emit('map:taxi:track', {
        item: {
          id: item.id,
          position: item.position,
          registration: item.registration,
          driver: item.driver,
        },
      });
    },
  }),
);
