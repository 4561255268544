
require('../common/map-component.html');

require('./order-list.html');

const RegisterHtmlTemplate = require('polymer-webpack-loader/register-html-template');

RegisterHtmlTemplate.register("<dom-module id=order-list-view> <template> <style include=shared-styles></style> <style>:host{display:block;@apply (--layout-fit);@apply (--layout);@apply (--layout-horizontal);}.-shadow{box-shadow:-2px 2px 4px rgba(0,0,0,.15)}</style> <div class=\"relative flex -shadow\"> <order-list></order-list> </div> </template> </dom-module>");

require('./order-list-view.js');
