global.App.FormElementBehavior = global.App.utils.merge({}, global.App.ElementBase, {
  properties: {
    data: {
      type: Object,
    },
    item: {
      type: Object,
      notify: true,
    },
    issues: {
      type: Object,
      notify: true,
      value() {
        return {};
      },
    },
  },

  do() {
    this.$.form.submit();
  },

  submit() {
    this.$.form.submit();
  },

  _successHandler(event, detail) {
    debug('FormElementBehavior._successHandler', arguments);
    this.$.form.reset();
    this.issues = {};
    this.fire('success', detail.response);
  },

  _failureHandler(event, detail) {
    debug('FormElementBehavior._failureHandler', arguments);
    // let message;
    // let response = event.detail.request.xhr.response;
    // if (response) {
    //   message = response.message;
    //   this.issues = response.issues;
    // } else {
    //   message = "Echec de chargement!";
    // }
    this.fire('failure', event.detail);
  },
});
